<template>
  <div class="container">
    <div class="card-info-box">
      <div class="card-info">
        <div class="card">
          <div class="card-no">贵A12345</div>
          <div class="card-type" v-if="info.plate.length > 7">新能源</div>
        </div>
        <!--        <div class="status">月卡过期</div>-->
      </div>
      <div class="card-info-item">
        <div>车场名称</div>
        <div>xxx停车场</div>
      </div>
      <div class="card-info-item">
        <div>入场时间</div>
        <div>{{ info.enterTime }}</div>
      </div>
      <div class="card-info-item">
        <div>停车费用</div>
        <div class="price">
          <!-- isNaN(info.totalAmount - info.paidAmount) ? '' : (info.totalAmount - info.paidAmount).toFixed(2) -->
          200
        </div>
      </div>
      <div class="card-info-item">
        <div>出场时间</div>
        <div>{{ info.exitTime }}</div>
      </div>
      <div class="card-info-item">
        <div>停车时长</div>
        <div>{{ info.duration }}</div>
      </div>
      <div class="card-info-item" v-if="info.paymentTime">
        <div>缴费时间</div>
        <div>{{ info.paymentTime }}</div>
      </div>
      <div class="card-info-item" v-if="info.paymentTime">
        <div class="time-out">超时时段</div>
        <div class="time-out">{{ info.timeOutPeriod }}</div>
      </div>
      <div class="card-info-item" v-if="info.paymentTime">
        <div class="time-out">超时费用</div>
        <!-- (info.totalAmount - info.paidAmount).toFixed(2) -->
        <div class="time-out">{{ info.overTimeAmount }}</div>
      </div>
    </div>

    <div class="footer">
      <div class="pay-info">
        <span class="text">应付金额</span>
        <span class="price">￥20</span>
      </div>
      <Debounce :time="1500" :isDebounce="2">
        <van-button class="class-button" type="info">立即付款</van-button>
      </Debounce>
    </div>

  </div>
</template>

<script>
export default {
  name: 'parkingRecord',
  data() {
    return {
      info: {
        plate: '',
      },
    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
/deep/ .line1:after {
  height: 0 !important;
}

.container {
  text-align: left;
  padding-bottom: 6rem;

  .card-info-box {
    margin: 1rem;
    padding: 1.5rem;
    background: white;
    border-radius: 0.8rem;

    .card-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;

      .card {
        display: flex;
        align-items: center;

        .card-no {
          font-size: 1.8rem;
          font-weight: bold;
          color: #2b86ff;
          letter-spacing: 2px;
        }

        .card-type {
          margin-left: 0.5rem;
          font-size: 1.17rem;
          font-weight: 400;
          color: #5ac40b;
        }
      }

      .status {
        font-size: 1.17rem;
        font-weight: 400;
        color: #ff5040;
      }
    }

    .card-info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.17rem;
      font-weight: 400;
      color: #303030;
      padding: 0.5rem 0;
      font-size: 1.4rem;

      .price {
        font-weight: 600;
      }

      .time-out {
        color: #ff9c50;
      }
    }
  }

  .charge-time {
    margin: 1rem;

    .title {
      padding-left: 1rem;
      margin: 2rem 0 1rem 0;
      font-size: 1.17rem;
      font-weight: 400;
      color: #838da0;
    }

    .item-box {
      .item {
        background: white;
        border-radius: 0.8rem;
        padding: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-status {
          display: flex;
          align-items: center;

          .round {
            position: relative;
            margin: 0 1.5rem 0 1rem;
            border-radius: 50%;
            width: 0.7rem;
            height: 0.7rem;

            &.in {
              background: #2b86ff;
            }

            &.overdue {
              background: #ff5040;
            }

            &.timeout {
              background: #ff9c50;
            }

            &.out {
              width: 0.6rem;
              height: 0.6rem;
              border: 0.1rem solid #99c5ff;
              background: transparent;
            }

            &.line:after {
              position: absolute;
              top: 0.6rem;
              content: "";
              height: 5.5rem;
              width: 0.3rem;
              border-right: 0.1rem dashed #b9c1cb;
            }
          }

          .time {
            div:first-child {
              font-size: 1.5rem;
              font-weight: 500;
              color: #303030;
            }

            div:last-child {
              font-size: 1rem;
              font-weight: 400;
              color: #838da0;
            }
          }
        }

        .center-tx {
          font-size: 1.33rem;
          font-weight: 300;
          color: #666666;
        }

        .right-tx {
          font-size: 1.17rem;
          font-weight: 400;
          color: #303030;
        }
      }
    }
  }

  .copyRight {
    // position: absolute;
    // bottom: 8rem;

    img {
      width: 100%;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    background: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 2rem);
    box-shadow: 0 0 7px 1px #bbbbbb;

    .pay-info {
      display: flex;
      flex-direction: column;

      .text {
        font-size: 1rem;
        font-weight: 300;
        color: #242629;
      }

      .price {
        font-size: 1.5rem;
        font-weight: bold;
        color: #ff5040;
      }
    }

    .class-button {
      border-radius: 0.8rem;
      width: 11rem;
      height: 3.67rem;
      background: #2b86ff;
    }
  }
}
</style>
