<template>
  <div class="container">
    <div class="content">
      <div class="bg-div">
        <div class="title-div">请输入缴费的车辆车牌</div>
      </div>
      <div class="line-div"></div>

      <plateKeyBoard v-model="licenseNumber" />

      <div class="opt-div" @click="createMonthCard">
        <div class="title">月卡开卡</div>
        <img class="img-next" src="../../../assets/monthCard/next-icon.png" />
      </div>
    </div>

    <van-button
      class="pay-btn"
      @click="checkPlate"
      type="info"
      loading-text="加载中..."
      block
      round
      >查询</van-button
    >
  </div>
</template>

<script>
import plateKeyBoard from "@/components/plateKeyboard";
import { payDetail } from "@/api/monthCard";
import { Notify } from "vant";
export default {
  components: {
    plateKeyBoard,
  },
  created() {
    this.parklotId = this.$route.query.parklotId;
    if (
      this.$route.query.userId != "" &&
      this.$route.query.userId != undefined
    ) {
      this.userId = this.$route.query.userId;
    }
  },
  data() {
    return {
      show: false, //是否展示支付弹框
      licenseNumber: "",
      parklotId: "",
      userId: "",
    };
  },
  methods: {
    createMonthCard() {
      this.$router.push(
        "/ct/monthCardCreate?plate=" +
          this.licenseNumber +
          "&parklotId=" +
          this.parklotId +
          "&userId=" +
          this.userId
      );
    },

    //下单
    checkPlate() {
      if (this.licenseNumber == "") {
        Notify({ type: "warning", message: "请输入车牌" });
        return;
      }
      let jsonData = {
        plate: this.licenseNumber,
        parklotId: this.parklotId,
      };

      payDetail(jsonData).then((res) => {
        console.log(res);
        if (res.data != null) {
          // Notify({ type: "warning", message: "月卡已存在" });
          this.$router.push(
            "/ct/cardPayDetail?plate=" +
              this.licenseNumber +
              "&parklotId=" +
              this.parklotId +
              "&userId=" +
              this.userId
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
>>> .van-button--round {
  border-radius: 1rem !important;
}
.container {
  padding: 1.6rem 1.6rem 1rem 1.6rem;
  min-height: calc(100vh - 3.2rem);
  background: #f6f7fb;

  .content {
    padding: 1.2rem;
    background-color: #ffffff;
    border-radius: 10px;
    .bg-div {
      position: relative;
      margin-top: 5rem;
      width: 21.6rem;
      height: 9.3rem;
      border-radius: 0rem 0rem 0rem 0rem;
      opacity: 1;
      background: url("../../../assets/monthCard/bg-query.png") no-repeat;
      .title-div {
        bottom: -1rem;
        margin-left: 2.3rem;
        position: absolute;
        font-size: 1.8rem;
        font-weight: 500;
        color: #454545;
      }
    }
    .line-div {
      margin-bottom: 9rem;
      margin-left: 2.3rem;
      margin-top: 3.8rem;
      width: 3.2rem;
      height: 0.6rem;
      background: #e3e3e3;
      border-radius: 0rem 0rem 0rem 0rem;
      opacity: 1;
    }
    .plate-div {
      display: flex;
      height: 5rem;
      margin-top: 9rem;
      margin-left: 2.3rem;
      margin-right: 2.3rem;
      .item {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        .spot {
          width: 0.2rem;
          height: 0.2rem;
          margin-left: 1rem;
          margin-right: 1rem;
          background: #3a7cdd;
        }
        .value {
          text-align: center;
          margin-right: 0.2rem;
          width: 2rem;
          font-size: 2.6rem;
          font-weight: 400;
          color: #303030;
        }
        .line {
          margin-top: 0.2rem;
          width: 2rem;
          height: 0.01rem;
          opacity: 1;
          background: #3a7cdd;
        }
      }
    }
    .opt-div {
      display: flex;
      text-align: center;
      margin-top: 12rem;
      margin-left: 2.3rem;
      margin-right: 2.3rem;
      background: #fafafa;
      padding: 1rem 2rem;
      border-radius: 1.2rem 1.2rem 1.2rem 1.2rem;
      opacity: 1;
      justify-content: space-between;
      .title {
        font-size: 1.4rem;
        font-weight: 500;
        color: #898989;
      }
      .next-icon {
        background: url("../../../assets/monthCard/next-icon.png");
      }
      .img-next {
        width: 0.9rem;
        height: 1.31rem;
        border-radius: 0rem 0rem 0rem 0rem;
        opacity: 1;
      }
    }
  }
  .pay-btn {
    margin-top: 3rem;
  }
}
</style>
