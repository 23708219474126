<template>
  <div class="container">
    <div class="header">
      <div class="address-info">
        <div class="car-info">
          <div class="plate">{{ data.plates }}</div>
          <div class="parking-lot">{{ data.parklotName }}</div>
        </div>
        <div class="next-icon">
          <!-- <img src="../../../assets/monthCard/next-icon.png" /> -->
        </div>
      </div>
    </div>
    <div class="content" style="margin-bottom: 1rem">
      <div class="list-content">
        <div class="item">
          <label>车位编号</label>
          <div>{{ data.placeCode ? data.placeCode : "-" }}</div>
        </div>
        <div class="item">
          <label>月卡状态</label>
          <div>{{ data.status == 1 ? "正常" : "-" }}</div>
        </div>
        <div class="item">
          <label>开通时间</label>
          <div>{{ data.createTime }}</div>
        </div>
        <div class="item">
          <label>有效期至</label>
          <div>
            {{ data.startDate != null ? initDate(data.startDate) : "" }} 至{{
              data.endDate != null ? initDate(data.endDate) : ""
            }}
          </div>
        </div>
      </div>
      <div class="card-title">月卡续费</div>
      <div class="choose-price">
        <van-row>
          <template v-for="(item, i) in data.monthList">
            <van-col span="6">
              <div class="choose-box" @click="chooseed(i, item)">
                <div
                  class="choose-item"
                  :class="chooseIndex == i ? 'chooseed' : ''"
                >
                  <div>{{ item.monthName }}</div>
                  <div>￥{{ item.totalPrice }}</div>
                </div>
              </div>
            </van-col>
          </template>
        </van-row>
      </div>
      <div class="list-content" style="margin-bottom: 0">
        <div class="item">
          <label>自定义续费</label>
          <div>
            <van-stepper
              v-model="stepperDefault"
              min="0"
              @change="stepperChange"
              disable-input
            />
          </div>
        </div>
        <div class="item">
          <label>续费金额</label>
          <div>
            <div class="div-pri">
              {{ totalPri != 0 ? "¥" : "" }}{{ totalPri }}{{ "元" }}
            </div>
          </div>
        </div>
      </div>

      <!-- 隐藏详细信息 -->
      <div class="list-content list-content-margin-top" v-if="stepperChangeNum">
        <div class="item">
          <label>续费开始时间</label>
          <div>
            {{ data.endDate != null ? initDate(data.endDate) : "" }}
          </div>
        </div>
        <div class="item">
          <label>有效期至</label>
          <div>{{ newEndDate }}</div>
        </div>
      </div>
    </div>
    <payPopup
      :show="show"
      :totalAmount="String(totalPri)"
      :isShowAl="data.scanAliMimiId == '' ? false : true"
      @closed="show = false"
      @onZfbPay="zfbPay"
      @onWxPay="wxPay"
    />
    <van-button
      v-show="totalPri > 0 ? true : false"
      :loading="btnLoading"
      :disabled="btnLoading"
      @click="renewalFee"
      type="info"
      loading-text="加载中..."
      block
      round
      >续费</van-button
    >
  </div>
</template>

<script>
import {
  payDetail,
  renewalFee,
  getRenewTimeDuration,
  cardPay,
} from "@/api/monthCard";
import moment from "moment";
import payPopup from "@/components/payPopup";
export default {
  name: "monthCardInfo",
  components: {
    payPopup,
  },
  created() {
    if (
      this.$route.query.userId != "" &&
      this.$route.query.userId != undefined
    ) {
      this.userId = this.$route.query.userId;
    }
    if (this.$route.query.cardId != undefined) {
      this.cardId = this.$route.query.cardId;
    }

    if (this.$route.query.plate != undefined) {
      this.plate = this.$route.query.plate;
    }

    if (this.$route.query.parklotId != undefined) {
      this.parklotId = this.$route.query.parklotId;
    }

    this.initData();
    // this.orderId = "1567721643030282241";
    // this.startSetInterval();
  },

  data() {
    return {
      show: false, //是否展示支付弹框
      parklotId: "", //车辆id
      userId: "", //用户id
      cardId: "",
      plate: "",
      interval: null,
      orderId: "", //订单号
      btnLoading: false,
      totalPri: 0, //金额
      optTime: 0,
      loopTime: 3000,
      optTimeMax: 180, //轮训时间停止时间
      stepperChangeNum: false,
      newEndDate: "", //结束时间
      id: "",
      stepperDefault: "0", //月数
      data: {},
      chooseData: {},
      chooseIndex: -1,
      newTime: {
        endDate: "",
        startDate: "",
      },
    };
  },
  methods: {
    stopClearInterval() {
      this.optTime = 0;
      clearInterval(this.interval);
      this.$router.replace("/payRes");
    },
    //定时 开始
    startSetInterval() {
      if (this.interval != null) {
        this.optTime = 0;
        clearInterval(this.interval);
      }

      this.interval = setInterval(this.cardPay, this.loopTime);
    },
    //查询支付接口
    cardPay() {
      this.optTime++;
      console.log(
        "optTime==" + this.optTime + ",this.optTimeMax==" + this.optTimeMax
      );
      if (this.optTime == this.optTimeMax) {
        clearInterval(this.interval);
      }
      let jsonData = { orderId: this.orderId };
      cardPay(jsonData).then((res) => {
        if (res.code == 200 && res.success == true && res.data == true) {
          this.stopClearInterval();
        }
      });
    },
    //支付宝支付
    zfbPay() {
      this.startSetInterval();
      this.show = false;
      console.log("zfb-----");
      let param =
        "type=card&orderId=" + this.orderId + "&userId=" + this.userId;
      let appId = this.data.scanAliMimiId;
      let page = "pages/jump-pay/index";

      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      if (isAndroid) {
        window.jstwebview.startAliMini(appId, page, param);
      }

      if (isIOS) {
        let paramJson = {
          type: "card",
          orderId: this.orderId,
          userId: this.userId,
        };
        let json = {
          appId: appId,
          page: page,
          param: paramJson,
        };
        console.log(json);
        window.webkit.messageHandlers.startAliMini.postMessage(json);
      }
    },
    //微信支付
    wxPay() {
      this.startSetInterval();
      this.show = false;
      console.log("wx-----");
      let param =
        "type=card&orderId=" + this.orderId + "&userId=" + this.userId;
      let appId = this.data.scanMimiId;
      let userName = this.data.scanMimiOriginalId;
      // let appId = "wxa8f99e190675abc0";
      // let userName = "gh_d86b1fb84d93";
      let path = "pages/jump-pay/index?" + param;

      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.jstwebview.startWxMini(appId, userName, path);
      }
      if (isIOS) {
        let json = {
          userName: userName,
          path: path,
        };

        window.webkit.messageHandlers.startWxMini.postMessage(
          JSON.stringify(json)
        );
      }
    },
    //初始化日期
    initDate(value) {
      if (value == null || value == "" || value == undefined) {
        return "";
      }
      return moment(value).format("YYYY-MM-DD");
    },
    //步进器变化监听
    stepperChange(event) {
      for (let i = 0; i < this.data.monthList.length; i++) {
        if (this.data.monthList[i].month == event) {
          this.chooseIndex = i;
        }
      }
      this.initEndTime(event);
    },
    //缴费结束时间
    initEndTime(event) {
      console.log("event", event);
      this.stepperDefault = event;

      if (event != 0) {
        console.log("data", this.data);
        let jsonData = {
          categoryId: this.data.categoryId,
          monthNum: event,
          nextStartTime: this.data.endDate,
        };

        console.log("jsonData", jsonData);
        getRenewTimeDuration(jsonData).then((res) => {
          this.stepperChangeNum = true;
          this.newEndDate = res.data.endDate;
          this.totalPri = res.data.totalAmount;
          // this.changRadio();
        });
      } else {
        this.stepperChangeNum = false;
        this.newEndDate = "";
        this.chooseIndex = -1;
        this.totalPri = 0;
      }

      // this.totalPri = event * this.data.monthList[0].unitPrice;
      // if (event != 0) {
      //   this.stepperChangeNum = true;
      //   this.newEndDate = moment(this.data.endDate)
      //     .add(event, "M")
      //     .format("YYYY-MM-DD");
      // } else {
      //   this.stepperChangeNum = false;
      //   this.newEndDate = "";
      //   this.chooseIndex = -1;
      // }
    },
    //初始化数据
    initData() {
      payDetail({
        parklotId: this.parklotId,
        plate: this.plate,
        cardId: this.cardId,
      }).then((res) => {
        console.log(res);
        this.data = res.data;
        this.cardId = res.data.id;
        this.stepperChange(1);
      });
    },
    //续费月份点击
    chooseed(i, item) {
      this.chooseIndex = i;
      this.initEndTime(item.month);
    },
    //下单
    renewalFee() {
      let jsonData = {
        cardId: this.cardId,
        renewalFeeNum: this.stepperDefault,
        renewalFeeMount: this.totalPri,
        reChargeType: "1",
        userId: this.userId,
        returnUrl: "www.baidu.com",
      };

      renewalFee(jsonData).then((res) => {
        console.log(res);
        this.orderId = res.data;
        this.show = true;
        // this.changRadio();
      });
    },
  },
};
</script>

<style scoped lang="scss">
>>> .van-button--round {
  border-radius: 1rem !important;
}
.container {
  padding: 1.6rem 1.6rem 1rem 1.6rem;
  min-height: calc(100vh - 3.2rem);
  background: #f6f7fb;

  .header {
    padding: 1.6rem;
    display: flex;
    background-color: #ffffff;
    margin-bottom: 1rem;
    border-radius: 1rem;

    .address-info {
      flex: 1;
      display: flex;
      justify-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      .car-info {
        .plate {
          text-align: left;
          font-size: 2rem;
          font-weight: 500;
          color: #3c90ff;
        }
        .parking-lot {
          margin-top: 0.5rem;
          font-size: 1.4rem;
          text-align: left;
          background: #ffffff;
          border-radius: 2rem 2rem 2rem 2rem;
          opacity: 1;
        }
      }
      .next-icon {
        text-align: right;
      }

      div {
        flex: 1;
      }

      .plate {
        font-size: 1.4rem;
        color: #303030;
      }
    }
  }

  .content {
    padding: 1.6rem;
    background-color: #ffffff;
    border-radius: 10px;

    .list-content-margin-top {
      margin-top: 0.6rem;
    }

    .list-content {
      margin-bottom: 1.6rem;
      .margin-top-bottom {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .item {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        align-items: center;
        margin-bottom: 1rem;

        label {
          font-size: 1.4rem;
          font-weight: 500;
          color: #9a9a9a;
        }

        div {
          flex: 1;
          text-align: right;
          font-size: 1.2rem;
          font-weight: 500;
          color: #9a9a9a;
        }
        //金额
        .div-pri {
          font-size: 2rem;
          font-weight: 500;
          color: #2376e5;
        }
      }

      .item:last-child {
        margin-bottom: 0;
      }
    }

    .card-title {
      display: flex;
      justify-items: center;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 400;
      color: #303030;
      margin-bottom: 1rem;
    }

    .card-title:before {
      content: " ";
      width: 3rem;
      height: 3rem;
      background: url("../../../assets/monthCard/pay-title.png") no-repeat;
      background-size: 100% 100%;
      display: block;
      margin-right: 1rem;
    }

    .choose-price {
      .choose-box {
        padding: 0.5rem;

        .choose-item {
          display: flex;
          background-color: #f6f9fb;
          flex-direction: column;
          width: 6.2rem;
          height: 6.1rem;
          border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
          opacity: 1;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;

          div:first-child {
            font-size: 1rem;
            font-weight: 400;
            color: #676767;
          }

          div:last-child {
            margin-top: 0.3rem;
            font-weight: bold;
            color: #313131;
            font-size: 1.2rem;
            font-weight: 500;
            padding-left: 10px;
            padding-right: 10px;
          }
        }

        .chooseed {
          background-color: #2b86ff;

          div:first-child {
            font-size: 1rem;
            font-weight: 400;
            color: #ffffff;
          }

          div:last-child {
            margin-top: 0.3rem;
            color: #ffffff;
            font-weight: bold;
            font-size: 1.2rem;
            font-weight: 500;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }

  .pay-btn {
    position: fixed;
    bottom: 2rem;
    height: 4rem;
    left: 1.6rem;
    right: 1.6rem;
    background: #3d90ff;
    border-radius: 12px;
    text-align: center;
    line-height: 4rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
  }
}
</style>
