<template>
  <div class="container">
    <div class="success" v-if="payStatus==2">
      <img src="../../assets/monthCard/pay-success.png" />
      <div>支付完成</div>
      <div :class="{'btn-success':isSuccess,btn:!isSuccess}" @click="close">{{isSuccess?"":"返回首页"}}</div>
    </div>
    <div class="error" v-if="payStatus!=2 && payStatus!=-1 ">
      <img src="../../assets/monthCard/pay-error.png" />
      <div>支付失败，请重试</div>
      <div class="btn" @click="toIndex">重新支付</div>
    </div>
  </div>
</template>

<script>
import {OrderStatus} from '@/api/searchPlate/index'
import {getAccessToken} from '@/util/store'
import {apiCouponFlagBySuccessPay} from '@/api/coupon'

export default {
  name: "payResult",
  created(){
    this.initData();
  },
  data(){
    return {
      isSuccess:false,
      payStatus:-1,
    }
  },
  watch:{
    payStatus(val){
      if(val==2){
        apiCouponFlagBySuccessPay(window.localStorage.getItem('openId')).then(res=>{
          if(res.success && res.data.couponFlag){
            this.isSuccess=true;
          }
        }).catch(err => {
          console.log("payResult.vue::apiCouponFlagBySuccessPay",err);
        })
      }
    }
  },
  methods:{
    initData(){
      OrderStatus({outTradeNo:this.$route.query.orderId}).then(res=>{
        this.payStatus = res.data.status;
      })
    },
    close(){
      if(this.isSuccess){
        if(getAccessToken()){
          this.$router.replace({
            path:"/couponList/getList",
            query:{
              isPay:1
            }
          });
        }else{
          this.$router.replace({
            path:"/couponList",
            query:{
              isPay:1
            }
          });
        }
        return;
      }
      this.$router.replace('/');
      // //关闭安卓
      // document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false);
      // //这个可以关闭ios系统的手机
      // WeixinJSBridge.call('closeWindow');
    },
    toIndex(){
      this.$router.replace('/searchPlate?openId=' + window.localStorage.getItem('openId'));
    },
  },
}
</script>

<style scoped lang="scss">
.container{
  padding-top: 5rem;
  min-height: calc(100vh - 5rem);
  background: #F6F7FB;
  .success{
    text-align: center;
    img{
      width: 9rem;
      height: 9rem;
    }
    div{
      font-size: 1.8rem;
      font-weight: 600;
      color: #303030;
      line-height: 50px;
    }
  }
  .error{
    text-align: center;
    img{
      width: 9rem;
      height: 9rem;
    }
    div{
      font-size: 1.8rem;
      font-weight: 600;
      color: #303030;
      line-height: 50px;
    }
  }
  .btn-success{
    position: absolute;
    height: 20.6rem;
    width: 36rem;
    background: url('../../assets/coupon/pay-success.png') no-repeat;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5rem;
    background-position: center;
    background-size: contain;
  }
  .btn{
    position: absolute;
    height: 5rem;
    width: 16.7rem;
    line-height: 5rem;
    text-align: center;
    background-color: #ffffff;
    color:#3D90FF !important;
    border-radius: 10px;
    left: 50%;
    margin-left: -8.3rem;
    bottom: 5rem;
  }
}
</style>
