<template>
  <div class="container">
    <div class="header">
      <img src="../../assets/monthCard/resultHeader.png" alt="resuktHeader" />
      <div class="title">我的车辆</div>
      <div class="plate">{{plate.slice(0,2)}}<i class="circle"></i>
        {{plate.slice(2)}}
      </div>
      <div class="plate-tip">车牌号</div>
    </div>
    <div class="content">
      <div class="nav">
        <div>月卡信息</div>
        <div @click="toRenewRecord" class="renew_record">缴费记录
          <van-icon name="arrow" />
        </div>
      </div>
      <template v-for="(item,i) in list">
        <div class="list" :key="item.id">
          <div class="list-header">
            <div class="ico"></div>
            <div class="address">{{item.parklotNames}}</div>
            <!-- <div class="btn" v-if="item.status == 1" @click="renew(item.id)">续费</div> -->
          </div>
          <div class="list-content">
            <div class="item">
              <label>卡号</label>
              <div>{{item.no}}</div>
            </div>
            <div class="item ">
              <label>有效期至</label>
              <div>{{item.endDate}}</div>
            </div>
            <div class="item ">
              <label>剩余天数</label>
              <div>{{item.endDate | dateDiff}}</div>
            </div>
            <div class="item">
              <van-button type="info" block class="item_btn" v-if="item.status == 1" @click="renew(item.id)">立即续费</van-button>
            </div>
          </div>
          <div class="overdue" v-if="item.status != 1"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { GetCardListByNum } from '@/api/monthCard/index'
export default {
  name: "searchResult",
  data() {
    return {
      plate: '',
      list: [],
      demo: '',
    }
  },
  filters: {
    dateDiff(value) {
      let s1 = value;
      s1 = new Date(s1.replace(/-/g, "/"));
      let s2 = new Date();
      let days = s1.getTime() - s2.getTime();
      let time = parseInt(days / (1000 * 60 * 60 * 24))
      if (time < 4 && time != 0) {
        return `不足${time}天`
      } else {
        return time + '天';
      }
    },
  },
  computed: {

  },
  created() {
    console.log(this.$route.query.plate);
    this.plate = this.$route.query.plate;
    this.initData()
  },
  mounted() {
    console.log()
  },
  methods: {
    initData() {
      GetCardListByNum({ plate: this.plate }).then(res => {
        console.log(res)
        this.list = res.data;
      })
    },
    //续费
    renew(id) {

      this.$router.push('/monthCardInfo?id=' + id);
    },
    toRenewRecord() {
      console.log(this.demo);
      this.$router.push('/renewRecord?plate=' + encodeURIComponent(this.plate));
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  padding: 1.6rem;
  min-height: calc(100vh - 3.2rem);
  background: #f6f7fb;
  .header {
    position: relative;
    margin-bottom: 2.4rem;
    img {
      width: 100%;
    }
    .title {
      position: absolute;
      right: 4rem;
      top: 3.3rem;
      font-weight: 300;
      color: #ffffff;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      font-size: 1.4rem;
    }
    .plate {
      position: absolute;
      bottom: 4rem;
      left: 2.3rem;
      display: flex;
      justify-items: center;
      align-items: center;
      font-size: 2.4rem;
      line-height: 2.4rem;
      text-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      color: #ffffff;
      .circle {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: #ffffff;
        margin: 0 0.5rem;
      }
    }
    .plate-tip {
      position: absolute;
      bottom: 1.5rem;
      left: 2.3rem;
      font-size: 1.4rem;
      color: #ffffff;
    }
  }
  .nav {
    overflow: hidden;
    font-size: 1.4rem;
    line-height: 4rem;
    font-weight: 600;
    :first-child {
      color: #303030;
      float: left;
    }
    :last-child {
      color: #1989fa;
      float: right;
      display: flex;
      align-items: center;
    }
  }
  .list {
    padding: 0.3rem;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 1.4rem;
    position: relative;
    .list-header {
      padding: 0 2rem;
      background: #fdfdfe;
      height: 4.5rem;
      font-size: 1.6rem;
      font-weight: 600;
      color: #303030;
      display: flex;
      justify-items: center;
      align-items: center;
      margin-bottom: 0.3rem;
      .ico {
        width: 2.4rem;
        height: 2.4rem;
        background: url("../../assets/monthCard/address-ico.png") no-repeat;
        background-size: 100%;
        margin-right: 0.8rem;
      }
      .address {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .btn {
        width: 5rem;
        height: 1.7rem;
        border-radius: 3px;
        border: 1px #3d90ff solid;
        font-size: 1.3rem;
        text-align: center;
        line-height: 1.7rem;
        color: #3d90ff;
      }
    }
    .list-content {
      padding: 2rem 2rem;
      background-color: #fafbfd;
      border-radius: 0 0 8px 8px;
      .item {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        margin-bottom: 1rem;
        label {
          color: #666666;
        }
        div {
          flex: 1;
          text-align: right;
        }
      }
      .item_btn {
        margin-top: 1rem;
        border-radius: 0.5rem;
      }
    }
    .overdue {
      width: 4rem;
      height: 4rem;
      position: absolute;
      right: 0;
      top: 0;
      background: url("../../assets/monthCard/overdue.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
