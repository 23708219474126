<template>
  <div class="container">
    <div class="header" v-if="plateList.length===0">
      <img src="../../assets/monthCard/searchCardBg.png" alt="header" />
    </div>
    <article class="content">
      <div class="title">
        <div class="">查询月卡</div>
        <div class="tip">请输入缴费的车辆车牌</div>
      </div>
      <plateKeyBoard v-model="licenseNumber" />
    </article>
    <div class="mycarlist" v-if="plateList.length!=0">
      <div class="title">我的月卡</div>
      <div :class="['list_item',{'yellow':item.cardEndTime!==''}]" v-for="(item,index) in plateList" :key="index" @click="jumpsSarchResult(item.plate)">
        <div class="tip">月卡</div>
        <span class="plate">{{item.plate}}</span>
      </div>
    </div>
    <div class="search-btn" @click="searchByPlate">
      查询月卡
    </div>
  </div>
</template>

<script>
import { getBindCarList } from '@/api/my/myCar/index'
import { GetCardListByNum } from '@/api/monthCard'
import { getAccessToken } from '@/util/store'
import plateKeyBoard from '@/components/plateKeyboard'
export default {
  name: "index",
  components: {
    plateKeyBoard
  },
  data() {
    return {
      licenseNumber: '贵',
      plateList: [],
      formData: {
        plate: '',
      },
    }
  },
  mounted() {
    this.getListThreeMinePlate()
  },

  methods: {
    searchByPlate() {
      if (this.licenseNumber.length < 7) {
        return this.$msg('请输入完整车牌')
      }
      this.$msg({ message: '查询中...', forbidClick: true, duration: 500 })
      GetCardListByNum({ plate: this.licenseNumber }).then(res => {
        if (res.data.length === 0) {
          this.$msg('该车牌不存在月卡车')
        }
        else {
          this.$router.push('/searchResult?plate=' + encodeURIComponent(this.licenseNumber))
        }
      })
    },
    // 已知车牌直接跳转
    jumpsSarchResult(plate) {
      GetCardListByNum({ plate: plate }).then(res => {
        if (res.data.length === 0) {
          this.$msg('该车牌不存在月卡车')
        }
        else {
          this.$router.push('/searchResult?plate=' + encodeURIComponent(plate))
        }
      })
    },
    //首页获取车牌
    getListThreeMinePlate() {
      if (!getAccessToken()) {
        return
      }
      getBindCarList().then((res) => {
        this.plateList = res.data.filter(item => item.tag == 1 && item.tagNames.length > 1);
      })
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  .header {
    min-height: 20rem;
    text-align: center;

    img {
      width: 60%;
    }
  }

  .content {
    padding: 2.7rem 1.7rem 3rem 1.7rem;
    background: #fff;
    .title {
      text-align: left;
      margin-bottom: 1rem;
      div:first-child {
        font-size: 1.8rem;
        font-weight: 600;
        color: #303030;
        margin-bottom: 0.6rem;
      }

      .tip {
        font-size: 1.4rem;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .mycarlist {
    padding: 2.7rem 1.7rem 3rem 1.7rem;
    .title {
      font-size: 1.8rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #303030;
      text-align: left;
      margin-bottom: 1rem;
    }
    .list_item {
      position: relative;
      width: 100%;
      height: 8rem;
      background-image: url("../../assets/monthCard/month-card-item.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 1.5rem;
      &.yellow {
        background-image: url("../../assets/monthCard/jh-bg.png");
      }
      .tip {
        position: absolute;
        width: 3.6rem;
        height: 1.7rem;
        line-height: 1.7rem;
        background: #eab50c;
        box-shadow: 0rem 0.2rem 1.2rem 0rem rgba(237, 248, 255, 0.5);
        border-radius: 0.4rem;
        left: 1.5rem;
        top: 1rem;
        font-size: 1rem;
        color: #fff;
      }
      .plate {
        font-size: 1.6rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #303030;
        letter-spacing: 2px;
        position: absolute;
        left: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .number_box {
    height: 4.45rem;
    border-radius: 1rem;
    /*background-color: rgba(235, 235, 235, 1);*/
    margin: 3.3rem 0 1.3rem 0;
    text-align: center;
    line-height: 4.45rem;
    font-size: 2.1rem;
    display: flex;

    .first-card {
      background: url("../../assets/monthCard/first-card.png") no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
    }

    .new-card {
      background-color: #69c923;
      color: #ffffff;
      position: relative;

      .tips-card {
        position: absolute;
        font-size: 1.2rem;
        top: -30px;
        color: #69c923;
        width: 4rem;
        left: -3px;
      }
    }
  }

  .number_box span {
    border: 1px #e3e3e3 solid;
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 5px;
  }

  .number_box .plate-ico {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #333333;
    margin-top: 15px;
    margin-right: 5px;
  }

  .search-btn {
    position: fixed;
    width: 90%;
    color: #ffffff;
    border-radius: 10px;
    height: 5rem;
    font-size: 1.6rem;
    background-color: #3d90ff;
    text-align: center;
    line-height: 5rem;
    bottom: 1rem;
    left: 50%;
    margin-left: -45%;
  }
}
</style>
