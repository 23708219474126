<template>
  <div class="container">
    <article class="content">
      <div class="title">
        <div class="">查询月卡</div>
        <div class="tip">请输入缴费的车辆车牌</div>
      </div>
      <plateKeyBoard v-model="licenseNumber" />
      <div class="search-btn" @click="searchByPlate">
        查询月卡
      </div>
    </article>
    <div class="month-card">
      <div class='month-card-title'>我的月卡</div>
      <div>
        <div class="month-card-item" v-for="(item) in 10" :key="item.id">
          <div class="icon">月卡</div>
          <div class="plate">贵A12345</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plateKeyBoard from '@/components/plateKeyboard'
import { GetCardListByNum } from '@/api/monthCard'

export default {
  name: "index",
  components: {
    plateKeyBoard,
  },
  data() {
    return {
      licenseNumber: '',
      formData: {
        plate: '',
      },
    }
  },

  methods: {
    checkUp() {
      this.$router.push({ path: '/monthCardInfo' })
      return false
      if (this.licenseNumber.length >= 7) {
        this.search()
      } else {

      }
    },
    searchByPlate() {
      if (this.licenseNumber.length < 7) {
        return this.$msg('请输入完整车牌')
      }
      this.$msg({ message: '查询中...', forbidClick: true, duration: 500 })
      GetCardListByNum({ plate: this.licenseNumber }).then(res => {
        if (res.data.length === 0) {
          this.$msg('该车牌不存在月卡车')
        }
        else {
          this.$router.push('/searchResult?plate=' + encodeURIComponent(this.licenseNumber))
        }
      })
    },
    openKeyboard() {
      this.$refs.keyboard.isShow = true;
      this.$refs.keyboard.licenseNumber = this.licenseNumber;
      this.$refs.keyboard.type = false;
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  .header {
    text-align: center;

    img {
      width: 60%;
    }
  }

  .content {
    padding: 2.7rem 1.7rem 3rem 1.7rem;
    background-color: #ffffff;
    .title {
      div:first-child {
        font-size: 1.8rem;
        font-weight: 600;
        color: #303030;
        margin-bottom: 0.6rem;
      }

      .tip {
        font-size: 1.4rem;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .number_box {
    height: 4.45rem;
    border-radius: 1rem;
    /*background-color: rgba(235, 235, 235, 1);*/
    margin: 3.3rem 0 1.3rem 0;
    text-align: center;
    line-height: 4.45rem;
    font-size: 2.1rem;
    display: flex;

    .first-card {
      background: url("../../../assets/monthCard/first-card.png") no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
    }

    .new-card {
      background-color: #69c923;
      color: #ffffff;
      position: relative;

      .tips-card {
        position: absolute;
        font-size: 1.2rem;
        top: -30px;
        color: #69c923;
        width: 4rem;
        left: -3px;
      }
    }
  }

  .number_box span {
    border: 1px #e3e3e3 solid;
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 5px;
  }

  .number_box .plate-ico {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #333333;
    margin-top: 15px;
    margin-right: 5px;
  }
  .month-card {
    padding: 1.6rem;
    overflow-y: scroll;

    &-title {
      padding-bottom: 1.6rem;
      font-size: 1.8rem;
      font-weight: 500;
      color: #303030;
      text-align: left;
    }
    &-item {
      width: 100%;
      height: 8rem;
      margin-bottom: 1.2rem;
      background-image: url("../../../assets/monthCard/month-card-item.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      .icon {
        width: 3.6rem;
        height: 1.7rem;
        color: #ffffff;
        background: #eab50c;
        box-shadow: 0 2px 1.2rem 0 rgba(237, 248, 255, 0.5);
        border-radius: 4px;
        font-size: 1.2rem;
        position: absolute;
        top: 2rem;
        left: 1.8rem;
      }
      .plate {
        position: absolute;
        left: 1.8rem;
        bottom: 1.8rem;
        font-size: 1.6rem;
        font-weight: 600;
        color: #303030;
      }
    }
  }

  .search-btn {
    width: 96%;
    color: #ffffff;
    border-radius: 10px;
    height: 5rem;
    font-size: 1.6rem;
    background-color: #3d90ff;
    text-align: center;
    line-height: 5rem;
    position: relative;
    top: 1rem;
  }
}
</style>
