<template>
  <div class="container">
    <div class="no-car" v-if="carList.length === 0">
      <img alt="" src="../../../assets/my/car-bg.png">
      <div class="desc">您还没有绑定车辆</div>
      <div style="margin-top: 1.6rem">
        <van-button type="info" size="small" @click="$router.push('/bindCar')">新增车辆</van-button>
      </div>
    </div>

    <div class="car-list" v-else>
      <div class="car-item" v-for="(item,index) in carList" :key="index" @click="btnList(item)">
        <!-- <router-link :to="{name:'searchResult',query:{plate:item.plate}}"> -->

        <van-swipe-cell>
          <van-card :desc="item.tagNames|desc" :title="item.tagNames | descTitle" :price="item.plate" class="cars-card" />

          <!-- <template #right>
				<van-button square text="详情" type="primary" @click="descriptNews(item)" class="delete-button" />
				<van-button square text="解绑" type="danger" @click="unbind(item.plate,item.id)" class="delete-button" />
				</template> -->
        </van-swipe-cell>

        <!-- </router-link> -->
      </div>

    </div>

    <div v-if="carList.length !== 0" class="add-btn">
      <div class="add-btn" @click="$router.push('/bindCar')">
        <van-icon name="plus" size="1.6rem" />新增车辆
      </div>
    </div>
  </div>
</template>


<script>
import { getBindCarList, getCarTypeList, unbindCar } from '@/api/my/myCar/index'
export default {
  name: "index",
  data() {
    return {
      carList: [{}]
    }
  },
  created() {
  },
  filters: {
    desc(val) {
      return val?.length > 1 ? '月卡' : ''
    },
    descTitle(val) {
      return val?.[0] || '未知'
    }
  },
  mounted() {
    this.getCarList()

  },
  methods: {
    openKeyboard() {
      this.$refs.keyboard.isShow = true;
      this.$refs.keyboard.licenseNumber = this.licenseNumber;
      this.$refs.keyboard.type = false;
    },
    onConfirmCarType() {

    },
    onConfirmRelation() {

    },
    //点击跳转到详情
    btnList(item) {
      this.$router.push({ path: '/carDetails', query: { carDstails: item } })
    },
    getCarList() {
      getBindCarList().then(res => {
        this.carList = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .van-button--info {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0 2rem;
  margin-top: 1.5rem;
  color: #ffffff;
  border-radius: 1.8rem;
}

.container {
  .no-car {
    padding-top: 10vh;

    img {
      width: 94%;
      height: 25.6rem;
    }
  }

  .car-list {
    padding: 1.2rem 1.7rem 4rem;
    height: calc(100vh - 20rem);
    overflow-y: scroll;
    /deep/ .van-card__content {
      min-height: 7rem;
      text-align: left;
      padding: 10px 0 0 20px;
      box-sizing: border-box;
    }
    /deep/ .cars-card {
      background: url("../../../assets/monthCard/jh-bg.png");
      background-size: 100% 8rem;
      height: 8rem;
    }
    /deep/ .van-card {
      padding: 0;
    }
    .delete-button {
      height: 8rem;
    }
    .car-item {
      //   padding: 0 1.7rem;
      margin-bottom: 1.2rem;
      box-sizing: border-box;
      height: 8rem;
      //background: linear-gradient(146deg, rgba(255, 255, 255, 0.53) 0%, rgba(255, 255, 255, 0.47) 100%);
      border-radius: 1.2rem;
      border: 2px solid #ffffff;
      //   display: flex;
      overflow: hidden;
      justify-content: space-between;
      align-items: center;
      /deep/ .van-card__price {
        color: transparent;
        /deep/ .van-card__price-currency {
          display: none;
        }
        .van-card__price-integer {
          color: #323233;
        }
      }
      /deep/ .van-card__title {
        background: #eab50c;
      }
      /deep/ .van-card__desc {
        background: #3d90ff;
        margin-left: 4px;
      }
      /deep/ .van-card__title,
      /deep/ .van-card__desc {
        padding: 0 5px;
        display: inline-block;
        line-height: initial;
        font-size: 1.2rem;
        font-weight: 500;
        color: #ffffff;
        box-shadow: 0 2px 1.2rem 0 rgba(237, 248, 255, 0.5);
        border-radius: 4px;
      }
      .plate {
        padding: 3px 0;
        font-size: 1.6rem;
        font-weight: 600;
        color: #303030;
      }

      img {
        width: 8rem;
        height: 100%;
      }
    }
  }
  .add-btn {
    padding: 0 1.7rem;
    > div:first-child {
      width: 25.3rem;
      height: 4.4rem;
      line-height: 4.4rem;
      margin: 3rem auto 0;
      font-size: 1.6rem;
      font-weight: 600;
      color: #ffffff;
      background: #3d90ff;
      border-radius: 0.8rem;
    }
  }
}
</style>
