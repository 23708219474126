<template>
  <div class="container">
    <div class="card-info-box">
      <div class="card-info">
        <div class="card">
          <div class="card-no">{{ info.plate }}</div>
          <div class="card-type" v-if="info.plate.length > 7">新能源</div>
        </div>
        <!--        <div class="status">月卡过期</div>-->
      </div>
      <div class="card-info-item">
        <div>车场名称</div>
        <div>{{ info.parklotName }}</div>
      </div>
      <div class="card-info-item">
        <div>入场时间</div>
        <div>{{ info.enterTime }}</div>
      </div>
      <div class="card-info-item">
        <div>停车费用</div>
        <div class="price">
          {{
            isNaN(info.totalAmount - info.paidAmount)
              ? ""
              : (info.totalAmount - info.paidAmount).toFixed(2)
          }}
        </div>
      </div>
      <div class="card-info-item">
        <div>出场时间</div>
        <div>{{ info.exitTime }}</div>
      </div>
      <div class="card-info-item">
        <div>停车时长</div>
        <div>{{ info.duration }}</div>
      </div>
      <div class="card-info-item" v-if="info.paymentTime">
        <div>缴费时间</div>
        <div>{{ info.paymentTime }}</div>
      </div>
      <div class="card-info-item" v-if="info.paymentTime">
        <div class="time-out">超时时段</div>
        <div class="time-out">{{ info.timeOutPeriod }}</div>
      </div>
      <div class="card-info-item" v-if="info.paymentTime">
        <div class="time-out">超时费用</div>
        <div class="time-out">
          {{ (info.totalAmount - info.paidAmount).toFixed(2) }}
        </div>
      </div>
    </div>

    <!-- 优惠券单元格 -->
    <van-coupon-cell
      style="margin: 1rem; width: calc(100% - 2rem); border-radius: 0.8rem"
      v-if="loginStutas"
      :coupons="coupons"
      :chosen-coupon="chosenCoupon"
      @click="openCouponPoupFun"
    />
    <van-cell-group
      v-else
      style="margin: 1rem; width: calc(100% - 2rem); border-radius: 0.8rem"
    >
      <van-cell title="无法使用优惠券" value="未登录" />
    </van-cell-group>

    <div class="charge-time">
      <div class="title">详细计费时段表</div>
      <div class="item-box">
        <div class="item" v-for="(item, index) in timerShaft" :key="index">
          <div class="item-status">
            <div
              class="round in line"
              :class="timerShaft.length - 1 === index ? 'line1' : ''"
            ></div>
            <div class="time">
              <div>
                {{
                  item.time.split(" ")[1] ||
                  `${date.getHours()}
              :${
                date.getMinutes() < 9
                  ? "0" + date.getMinutes()
                  : date.getMinutes()
              }
              :${
                date.getSeconds() < 9
                  ? "0" + date.getSeconds()
                  : date.getSeconds()
              }`.replace(/\s*/g, "")
                }}
              </div>
              <div>
                {{
                  item.time.split(" ")[0] ||
                  `${date.getFullYear()}
              -${
                date.getMonth() + 1 < 9
                  ? "0" + (date.getMonth() + 1)
                  : date.getMonth() + 1
              }
              -${
                date.getDate() < 9 ? "0" + date.getDate() : date.getDate()
              }`.replace(/\s*/g, "")
                }}
              </div>
            </div>
          </div>
          <div class="center-tx">{{ item.explain }}</div>
          <div class="right-tx">{{ item.strem }}</div>
        </div>
      </div>
    </div>

    <div class="footer" v-if="info.totalAmount - info.paidAmount">
      <div class="pay-info">
        <span class="text">应付金额</span>
        <span class="price">￥{{ finalPay }}</span>
      </div>
      <Debounce :time="1500" :isDebounce="2">
        <van-button class="class-button" type="info" @click="getPayWay"
          >立即付款</van-button
        >
      </Debounce>
    </div>

    <!-- 优惠券列表 -->
    <van-popup
      v-model="showList"
      round
      position="bottom"
      style="height: 90%; padding-top: 4px"
    >
      <van-coupon-list
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        :disabled-coupons="disabledCoupons"
        :show-exchange-bar="false"
        @change="onChange"
        @exchange="onExchange"
      >
      </van-coupon-list>
    </van-popup>
  </div>
</template>

<script>
import {
  getChargeDetail,
  getPayWayByChannelId,
  payBefore,
} from "@/api/searchPlate/index";
import { apiPayAvailableCoupon, apiPayUnAvailableCoupon } from "@/api/coupon";
import { getAccessToken } from "@/util/store";
const coupon = [
  {
    available: 1,
    condition: "无使用门槛\n最多优惠12元",
    reason: "不可用原因，优惠券不可用时展示",
    value: 150,
    name: "优惠券名称",
    startAt: 1489104000,
    endAt: 1514592000,
    valueDesc: "1.5",
    unitDesc: "元",
    customValidPeriod: "自定义有效时间文案",
    // description:"dfsdfssfasdfs",
  },
  {
    available: 1,
    condition: "无使用门槛\n最多优惠12元",
    reason: "不可用原因，优惠券不可用时展示",
    value: 150,
    name: "优惠券名称",
    startAt: 1489104000,
    endAt: 1514592000,
    valueDesc: "1.5",
    unitDesc: "元",
    customValidPeriod: "自定义有效时间文案",

    // description:"dfsdfssfasdfs",
  },
];
export default {
  name: "parkingRecord",
  data() {
    return {
      info: {
        plate: "",
      },
      date: new Date(),
      form: {
        channelTodoId: "",
        payId: "",
        payType: "",
        returnUrl:
          "http://" + window.location.host + "/ZHParking/parkinguser/renewal",
      },
      timerShaft: [],
      // 优惠券
      chosenCoupon: -1,
      showList: false,
      coupons: [],
      disabledCoupons: [],
      favourableValue: 0,
      countTimeTimer: null,
      loginStutas: "",
    };
  },
  mounted() {
    this.getChargeInfo();
    this.loginStutas = getAccessToken();
  },
  computed: {
    finalPay() {
      let res =
        this.info.totalAmount - this.info.paidAmount - this.favourableValue;
      res = res < 0 ? 0 : res;
      return res.toFixed(2);
    },
  },
  watch: {
    chosenCoupon(val) {
      console.log(val);
      console.log(this.coupons[val]);
      if (val < 0) {
        this.favourableValue = 0;
      } else {
        let minusMoney =
          typeof this.coupons[val].value == "undefined" ||
          typeof this.coupons[val].value == "undefined"
            ? 0
            : this.coupons[val].value;
        // if(this.coupons[val].couponType==3){
        // this.favourableValue= minusMoney;
        // return;
        // }
        this.favourableValue = minusMoney / 100;
      }
    },
    showList(val) {
      if (!val) {
        this.countTimeTimer && clearTimeout(this.countTimeTimer);
      }
    },
  },
  created() {
    let _this = this;
    console.log(localStorage.getItem("openId"));

    document.addEventListener(
      "click",
      function (event) {
        if (event.target.className.indexOf("van-tab__text") != -1) {
          _this.countTimeTimer && clearTimeout(_this.countTimeTimer);
          _this.openCouponPoupFun();
        }
      },
      false
    );
  },
  methods: {
    // 获取可用优惠券
    getAvailableCoupon() {
      let curMony = (this.info.totalAmount - this.info.paidAmount).toFixed(2);
      apiPayAvailableCoupon({
        parklotId: this.info.parklotId,
        totalAmount: (this.info.totalAmount - this.info.paidAmount).toFixed(2),
        plate: this.$route.query.plate,
      }).then((res) => {
        // console.log("获取可用优惠券",res)
        console.log(res);
        let resourceData = res.data.list;
        let couponId = res.data.couponId;
        // reduceHourAmount
        // 抵扣时长 对应的抵扣金额
        let data = [...resourceData];
        data = data
          .map((item) => {
            let freeMony = parseFloat(
              (curMony - (curMony * item.discountAmount) / 10).toFixed(2)
            );
            item.reduceHour = item.reduceHour || 0;
            return {
              available: 1,
              couponType: item.couponType,
              // condition: item.couponType==1?`满${item.fullAmount}元减${item.reduceAmount}元`:item.couponType==2?`优惠${freeMony}元`:item.couponType==3?`抵扣${item.reduceHourAmount}元`:null,
              condition:
                item.couponType == 1
                  ? `满${item.fullAmount}元减${item.reduceAmount}元`
                  : item.couponType == 2
                  ? `优惠${freeMony}元`
                  : item.couponType == 3
                  ? " "
                  : null,
              value:
                item.couponType == 1
                  ? item.reduceAmount * 100
                  : item.couponType == 2
                  ? freeMony * 100
                  : item.couponType == 3
                  ? item.reduceHourAmount * 100
                  : 1,
              name: item.couponName, //'优惠券名称',
              startAt: parseInt(
                new Date(item.startDate.replace(/-/g, "/")).getTime() / 1000
              ),
              endAt: parseInt(
                new Date(item.endDate.replace(/-/g, "/")).getTime() / 1000
              ),
              endDate: item.endDate,
              valueDesc:
                item.couponType == 1
                  ? item.reduceAmount
                  : item.couponType == 2
                  ? parseFloat(item.discountAmount)
                  : item.couponType == 3
                  ? parseFloat(item.reduceHour)
                  : null,
              unitDesc:
                item.couponType == 1
                  ? "元"
                  : item.couponType == 2
                  ? "折"
                  : item.couponType == 3
                  ? "小时"
                  : null,
              couponId: item.userCouponId,
              customValidPeriod: item.endDateStr, //控件原因没有显示
            };
          })
          .sort((a, b) => b.value - a.value);

        data.map((item, index) => {
          if (item.couponId == couponId) {
            this.chosenCoupon = index;
            return;
          }
        });
        this.coupons = data;
      });
    },
    // 用户支付时, 不可用的优惠劵列表
    getUnAvailableCoupon() {
      this.disabledCoupons = [];
      let curMony = (this.info.totalAmount - this.info.paidAmount).toFixed(2);
      apiPayUnAvailableCoupon({
        parklotId: this.info.parklotId,
        totalAmount: (this.info.totalAmount - this.info.paidAmount).toFixed(2),
        plate: this.$route.query.plate,
      }).then((res) => {
        console.log("不可用的优惠劵列表", res);
        let data = res.data;
        data = data.map((item) => {
          let freeMony = parseFloat(
            (curMony - (curMony * item.discountAmount) / 10).toFixed(2)
          );
          return {
            available: 1,
            // condition: item.couponType==1?`满${item.fullAmount}元减${item.reduceAmount}元`:`优惠${freeMony}元`,
            condition:
              item.couponType == 1
                ? `满${item.fullAmount}元减${item.reduceAmount}元`
                : item.couponType == 2
                ? `优惠${freeMony}元`
                : item.couponType == 3
                ? " "
                : null,
            reason: item.remark, //==1?'未达到满减条件':'优惠券在该车场不可用',
            value:
              item.couponType == 1
                ? item.reduceAmount * 100
                : item.couponType == 2
                ? freeMony * 100
                : item.couponType == 3
                ? item.reduceHourAmount
                : 1,
            name: item.couponName, //'优惠券名称',
            startAt: parseInt(
              new Date(item.startDate.replace(/-/g, "/")).getTime() / 1000
            ),
            endAt: parseInt(
              new Date(item.endDate.replace(/-/g, "/")).getTime() / 1000
            ),
            endDate: item.endDate,
            valueDesc:
              item.couponType == 1
                ? item.reduceAmount
                : item.couponType == 2
                ? parseFloat(item.discountAmount)
                : item.couponType == 3
                ? parseFloat(item.reduceHour)
                : null,
            unitDesc:
              item.couponType == 1
                ? "元"
                : item.couponType == 2
                ? "折"
                : item.couponType == 3
                ? "小时"
                : null,
          };
        });
        this.disabledCoupons = data;
      });
    },
    // 倒计时功能
    timeCountDown(endDate) {
      let nowtime = new Date(), //获取当前时间
        endtime = new Date(endDate.replace(/-/g, "/")); //定义结束时间
      let lefttime = endtime.getTime() - nowtime.getTime(); //距离结束时间的毫秒数

      let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)); //计算天数
      leftd = leftd < 10 ? "0" + leftd : leftd;

      let lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24); //计算小时数
      lefth = lefth < 10 ? "0" + lefth : lefth;

      let leftm = Math.floor((lefttime / (1000 * 60)) % 60); //计算分钟数
      leftm = leftm < 10 ? "0" + leftm : leftm;

      let lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
      lefts = lefts < 10 ? "0" + lefts : lefts;

      return leftd + "天" + lefth + ":" + leftm + ":" + lefts; //返回倒计时的字符串
    },

    //获取详情
    getChargeInfo() {
      this.btnLoading = true;
      getChargeDetail(this.$route.query.plate).then((res) => {
        console.log(res);
        this.info = res.data;
        // this.info.totalAmount = 1 //给死支付金额
        this.timerShaft = res.data.dateList;
        if (this.loginStutas) {
          // 获取优惠金额
          this.getAvailableCoupon();
          this.getUnAvailableCoupon();
        }
      });
    },
    //获取支付方式后再支付
    async getPayWay() {
      this.btnLoading = true;
      await getPayWayByChannelId({ parklotId: this.info.parklotId }).then(
        (data) => {
          if (data.data.length > 0) {
            this.form.payType = data.data[0].payTypeVal;
            this.form.payId = data.data[0].payId;
            this.pay();
          } else {
            this.pay();
          }
        }
      );
    },
    //调用微信支付
    pay() {
      let baseURL =
        process.env.VUE_APP_PUBLIC_PATH === "/"
          ? ""
          : process.env.VUE_APP_PUBLIC_PATH;
      this.btnLoading = true;
      this.form.channelTodoId = this.info.id;
      this.form.returnUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        baseURL +
        "/monthPayResult"; //window.localStorage.getItem('url')
      this.form.source = "banma";
      this.form["userCouponId"] =
        this.chosenCoupon < 0 ? "" : this.coupons[this.chosenCoupon].couponId;
      payBefore(this.form)
        .then((res) => {
          // console.log(localStorage.getItem('openId'));
          // // window.location.href=(res.data.payData + '&openId=' + localStorage.getItem('openId'))
          let url = decodeURIComponent(res.data.payData);
          let tmpUrl = url.split("payUrl=");
          if (tmpUrl.length == 2) {
            tmpUrl = tmpUrl[1] ? tmpUrl[1] : url;
          } else {
            tmpUrl = url + "&openId=" + localStorage.getItem("openId");
          }
          window.location.href = tmpUrl;
          this.btnLoading = false;
        })
        .catch((e) => {
          this.btnLoading = false;
        });
    },
    // 打开优惠券弹窗
    openCouponPoupFun() {
      this.showList = true;
      this.countTimeTimer && clearTimeout(this.countTimeTimer);
      this.$nextTick(() => {
        // return

        const fun = () => {
          this.countTimeTimer && clearTimeout(this.countTimeTimer);
          let dompane = document.getElementsByClassName("van-tab__pane");
          let curDataIndex = 0;

          for (let i = 0; i < dompane.length; i++) {
            if (dompane[i].style.display == "") {
              curDataIndex = i;
            }
          }
          let curData = curDataIndex == 0 ? this.coupons : this.disabledCoupons;

          let dom =
            curDataIndex == 0
              ? dompane[0].getElementsByClassName("van-coupon__body")
              : dompane[1].getElementsByClassName("van-coupon__body"); //van-coupon--disabled

          for (let k = 0; k < dom.length; k++) {
            let ele = dom[k];
            let node = ele.getElementsByClassName("count-down-time");
            if (node.length === 0) {
              let li2 = document.createElement("p");
              li2.className = "count-down-time";
              li2.innerHTML = this.timeCountDown(curData[k].endDate) + "后到期";
              ele.appendChild(li2);
            } else {
              node[0].innerHTML =
                this.timeCountDown(curData[k].endDate) + "后到期";
            }
          }

          this.countTimeTimer = setTimeout(fun, 1000);
        };
        fun();
      });
    },
    // 优惠券
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
      this.countTimeTimer && clearTimeout(this.countTimeTimer);
    },
    onExchange(code) {
      // this.coupons.push(coupon);
      console.log(code);
    },
  },
  deactivated() {
    this.countTimeTimer && clearTimeout(this.countTimeTimer);
  },
};
</script>

<style scoped lang="scss">
/deep/ .van-coupon__name {
  width: 17rem;
}
/deep/ .line1:after {
  height: 0 !important;
}

.container {
  text-align: left;
  padding-bottom: 6rem;

  .card-info-box {
    margin: 1rem;
    padding: 1.5rem;
    background: white;
    border-radius: 0.8rem;

    .card-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;

      .card {
        display: flex;
        align-items: center;

        .card-no {
          font-size: 1.5rem;
          font-weight: bold;
          color: #303030;
          letter-spacing: 2px;
        }

        .card-type {
          margin-left: 0.5rem;
          font-size: 1.17rem;
          font-weight: 400;
          color: #5ac40b;
        }
      }

      .status {
        font-size: 1.17rem;
        font-weight: 400;
        color: #ff5040;
      }
    }

    .card-info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.17rem;
      font-weight: 400;
      color: #303030;
      padding: 0.5rem 0;

      .price {
        font-weight: 600;
      }

      .time-out {
        color: #ff9c50;
      }
    }
  }

  .charge-time {
    margin: 1rem;

    .title {
      padding-left: 1rem;
      margin: 2rem 0 1rem 0;
      font-size: 1.17rem;
      font-weight: 400;
      color: #838da0;
    }

    .item-box {
      .item {
        background: white;
        border-radius: 0.8rem;
        padding: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-status {
          display: flex;
          align-items: center;

          .round {
            position: relative;
            margin: 0 1.5rem 0 1rem;
            border-radius: 50%;
            width: 0.7rem;
            height: 0.7rem;

            &.in {
              background: #2b86ff;
            }

            &.overdue {
              background: #ff5040;
            }

            &.timeout {
              background: #ff9c50;
            }

            &.out {
              width: 0.6rem;
              height: 0.6rem;
              border: 0.1rem solid #99c5ff;
              background: transparent;
            }

            &.line:after {
              position: absolute;
              top: 0.6rem;
              content: "";
              height: 5.5rem;
              width: 0.3rem;
              border-right: 0.1rem dashed #b9c1cb;
            }
          }

          .time {
            div:first-child {
              font-size: 1.5rem;
              font-weight: 500;
              color: #303030;
            }

            div:last-child {
              font-size: 1rem;
              font-weight: 400;
              color: #838da0;
            }
          }
        }

        .center-tx {
          font-size: 1.33rem;
          font-weight: 300;
          color: #666666;
        }

        .right-tx {
          font-size: 1.17rem;
          font-weight: 400;
          color: #303030;
        }
      }
    }
  }

  .copyRight {
    // position: absolute;
    // bottom: 8rem;

    img {
      width: 100%;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    background: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 2rem);
    box-shadow: 0 0 7px 1px #bbbbbb;

    .pay-info {
      display: flex;
      flex-direction: column;

      .text {
        font-size: 1rem;
        font-weight: 300;
        color: #242629;
      }

      .price {
        font-size: 1.5rem;
        font-weight: bold;
        color: #ff5040;
      }
    }

    .class-button {
      border-radius: 0.8rem;
      width: 11rem;
      height: 3.67rem;
      background: #2b86ff;
    }
  }
}

/deep/ .van-coupon__name {
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 关于优惠券style
/deep/ .van-tab__pane {
  background-color: #dcdcdc;
}
/deep/ .van-popup {
  overflow: hidden;
}
/deep/ .van-coupon {
  background-color: transparent;
  box-shadow: 0 0 0;
  /deep/ .van-coupon__head {
    min-width: 13rem;
  }
  /deep/ .van-coupon__content {
    padding: 0.5rem 0;
    background-image: url("../../assets/searchPlate/use_coupon.png");
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 11rem;
  }
  /deep/ .van-coupon__amount,
  /deep/ .van-coupon__condition,
  /deep/ .van-coupon__description {
    margin: 0;
    border-top: 0 none;
  }
  /deep/ .van-coupon__corner {
    right: 1rem;
  }
  /deep/ .van-coupon__valid {
    // display:none !important;
  }
  .count-down-time {
    font-size: 1.2rem;
    font-weight: 600;
    color: #ff2e2e;
  }
}
</style>
