<template>
  <div class="container">
    <div class="header">
      <div class="img"></div>
      <div class="address-info">
        <div class="address">{{ data.parklotName }}</div>
        <div class="card-num">{{ data.no }}</div>
        <div class="plate">{{ data.plates }}</div>
      </div>
    </div>
    <div class="content" style="margin-bottom: 1rem">
      <div class="list-content">
        <div class="item">
          <label>车位编号</label>
          <div>{{ data.placeCode ? data.placeCode : '-' }}</div>
        </div>
        <div class="item ">
          <label>月卡状态</label>
          <div>正常</div>
        </div>
        <div class="item ">
          <label>开卡时间</label>
          <div>{{ data.createTime }}</div>
        </div>
        <div class="item ">
          <label>有效期至</label>
          <div>{{ data.endDate }}</div>
        </div>
        <template v-if="data.periodType==1 && data.endDate==''">
          <!--    
              periodType==1 自然月 _ 例: 2021.10.01 00:00:00 - 2021.10.31 23:59:59
              periodType==2 非自然月1 _ 例: 2021.10.06 00:00:00 - 2021.11.06 23:59:59
              periodType==3 非自然月2 _ 例：2021.10.06 00:00:00 - 2021.11.05 23:59:59
              periodType==4 日卡（可选择任意天数续费） _ 例：2021.10.06 00:00:00 - 2021.10.20 23:59:59
          -->
          <div class="mui-flex" style="margin-top: -10px;">
            <div class="mui-cell fixed item">
              <label style=" padding-top: 12px;">续费方式</label>
            </div>
            <van-radio-group class="mui-cell" v-model="formData.reChargeType" @change="changRadio">
              <van-cell-group>
                <van-cell :title="monthToDay" clickable @click="formData.reChargeType = '1'">
                  <template #right-icon>
                    <van-radio name="1" />
                  </template>
                </van-cell>
                <van-cell title="下月生效" clickable @click="formData.reChargeType = '2'">
                  <template #right-icon>
                    <van-radio name="2" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
        </template>
      </div>
    </div>

    <div class="content" style="margin-bottom: 6rem">
      <div class="card-title">月卡续费</div>
      <div class="choose-price">
        <van-row>
          <div v-for="(item,i) in data.monthList" v-bind:key="i">
            <van-col span="8">
              <div class="choose-box" @click="nativeChooseed(i,item)">
                <div class="choose-item" :class="chooseIndex==i ?'chooseed':''">
                  <div>{{ item.monthName }}</div>
                  <div><label>&yen;</label>{{ item.totalPrice }}</div>
                </div>
              </div>
            </van-col>
          </div>
        </van-row>
      </div>

      <div class="list-content" style="margin-bottom: 2rem">
        <div class="item ">
          <label>自定义续费:</label>
          <div>
            <van-stepper v-model="customMonth" @change="onCustomMonthChange" min="0" max="60" step="1" />
          </div>
        </div>
        <div class="item item-custom">
          <label>续费金额:</label>
          <div><span>¥</span><span style="font-size:2rem">{{totalPrice}}</span></div>
        </div>
      </div>

      <!-- 隐藏详细信息 -->
      <div class="list-content" style="margin-bottom: 0" v-if="newTime.endDate!=''">
        <div class="item ">
          <label>续费开始时间:</label>
          <div>{{ newTime.startDate }}</div>
        </div>
        <div class="item ">
          <label>有效期至:</label>
          <div>{{ newTime.endDate }}</div>
        </div>
      </div>

    </div>

    <Debounce :time='1000' :isDebounce="3">

      <van-button class="fixed_btn" :loading="btnLoading" :disabled="btnLoading" @click="getPayWay" type="info" loading-text="加载中..." block round>续费</van-button>

    </Debounce>

  </div>
</template>

<script>
import { GetCardDetail, GetNewestTimeDuration, RenewalFee, getPayWayByChannelId } from '@/api/monthCard'

export default {
  name: "monthCardInfo",
  created() {
    this.id = this.$route.query.id;
    this.initData()
  },
  computed: {
    monthToDay: function () {
      let curDate = new Date();
      let curMonth = curDate.getMonth(); //当前月份 需要加1
      curDate.setMonth(curMonth + 1);
      curDate.setDate(0); //关键
      curDate.getDate(); //计算的当月总天数
      let toDay = curDate.getDate() - new Date().getDate() + 1;
      return '立即生效（本月剩余' + toDay + '天）'
    },
  },
  data() {
    return {
      btnLoading: false,
      id: '',
      data: {},
      chooseData: {},
      chooseIndex: 0,
      //用于查询最新续费时间
      detailTimeData: {
        cardId: '',
        monthNum: '',
      },
      //续费参数
      formData: {
        cardId: '',  //id
        renewalFeeMount: '', //缴费金额
        renewalFeeNum: '', //缴费月数
        reChargeType: '2',//是否第一次开卡
        // returnUrl: 'http://' + window.location.host + '/monthPayResult',
        returnUrl: process.env.VUE_APP_PUBLIC_PATH === '/' ? window.location.protocol + '//' + window.location.host + '/monthPayResult' : window.location.protocol + '//' + window.location.host + process.env.VUE_APP_PUBLIC_PATH + '/monthPayResult',
        payType: '',
        payId: '',
      },
      newTime: {
        endDate: '',
        startDate: '',
      },
      //自定义续费
      customMonth: 0,
      //续费金额
      totalPrice: 0
    }
  },
  mounted() {
    this.WXSDK_PACKAGE.getWxSign();
    this.WXSDK_PACKAGE.shareIndexPage()
  },
  methods: {
    //获取支付方式后再支付
    getPayWay() {
      if (parseFloat(this.formData.renewalFeeMount) <= 0) {
        this.$msg('0元月卡请联系停车管理人员续费');
        return false;
      }
      const confirm = () => {
        this.btnLoading = true;
        getPayWayByChannelId({ parklotId: this.data.parklotIds }).then(data => {
          if (data.data.length > 0) {
            this.formData.payType = data.data[0].payTypeVal;
            this.formData.payId = data.data[0].payId;
            this.pay();
          } else {
            this.pay();
          }
        });
      }
      this.$dialog.confirm({
        title: '请确认续费时间',
        message: `续费开始时间:<span style='color:red'>${this.newTime.startDate}</span><br/>有效期至:<span style='color:red'>${this.newTime.endDate}</span>,是否继续?`,
      }).then(() => {
        // on confirm
        confirm()
      }).catch(() => {
        // on cancel
      });
    },
    pay() {
      if (!this.formData.renewalFeeMount) {
        this.$msg('请选择续费方式！');
        return false;
      }


      RenewalFee(this.formData).then(res => {
        //  window.location.replace(res.data.payData + '&openId=' + localStorage.getItem('openId'))
        let url = decodeURIComponent(res.data.payData);
        let tmpUrl = url.split('payUrl=');
        if (tmpUrl.length == 2) {
          tmpUrl = tmpUrl[1] ? tmpUrl[1] : url;
        } else {
          tmpUrl = url + '&openId=' + localStorage.getItem('openId');
        }
        window.location.href = tmpUrl;

        this.btnLoading = false;
      }).catch(e => {
        this.btnLoading = false;
      })
    },
    initData() {
      GetCardDetail({ id: this.id }).then(res => {
        this.data = res.data;
        // 默认选项
        if (this.data.monthList && this.data.monthList.length > 0) {
          this.chooseed(0, this.data.monthList[0])
        }
        this.changRadio();
      })
    },
    changRadio(name) {
      if (this.customMonth > 0) {
        this.searchCardTime(this.customMonth)
      } else {
        this.formData.cardId = this.id;
        this.formData.renewalFeeMount = this.data.monthList[this.chooseIndex].totalPrice;
        this.formData.renewalFeeNum = this.data.monthList[this.chooseIndex].month;
        this.searchCardTime(this.data.monthList[this.chooseIndex].month);
      }
    },
    onCustomMonthChange(val) {
      this.wrapChooseed(val);
    },
    nativeChooseed(i, item) {
      this.customMonth = 0;
      this.chooseed(i, item);
    },
    wrapChooseed(month) {
      if (month == 0) {
        //? 选择默认值
        this.chooseed(0, this.data.monthList[0]);
        return
      } else {
        for (let i in this.data.monthList) {
          if (this.data.monthList[i].month == month) {
            this.chooseed(i, this.data.monthList[i])
            return
          }
        }
      }
      this.chooseed(-1)
      this.getCustomProce(month)
    },
    chooseed(i, item) {
      if (i >= 0) {
        this.totalPrice = item.totalPrice;
        this.formData.cardId = this.id;
        this.formData.renewalFeeMount = item.totalPrice;
        this.formData.renewalFeeNum = item.month;
        this.searchCardTime(item.month);
      }
      this.chooseIndex = i;
    },
    // 获取自定义月份资费
    getCustomProce(month) {
      this.searchCardTime(month);
    },
    //查询续费最新时间
    searchCardTime(monthNum) {
      let data = {
        cardId: this.id,
        monthNum: monthNum,
        reChargeType: this.formData.reChargeType
      };
      GetNewestTimeDuration(data).then(res => {
        this.newTime = res.data;
        // 兼容以前的代码。自定义缴费查询结果
        if (this.chooseIndex < 0) {
          this.formData.cardId = this.id;
          this.formData.renewalFeeMount = res.data.totalAmount;
          this.formData.renewalFeeNum = monthNum;
          this.totalPrice = res.data.totalAmount;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
/deep/ .van-button--round {
  border-radius: 1rem !important;
}

.container {
  padding: 1.6rem 1.6rem 1rem 1.6rem;
  min-height: calc(100vh - 3.2rem);
  background: #f6f7fb;

  .header {
    padding: 1.2rem;
    display: flex;
    background-color: #ffffff;
    margin-bottom: 1rem;
    border-radius: 1rem;

    .img {
      width: 12rem;
      height: 8.2rem;
      background: url("../../../assets/monthCard/card-inof-img.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 1rem;
    }

    .address-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-items: center;
      height: 8.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      div {
        flex: 1;
      }

      .address {
        font-size: 1.6rem;
        font-weight: 600;
        color: #303030;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .card-num {
        font-size: 1.2rem;
        font-weight: 600;
        color: #999;
        line-height: 2.4rem;
      }

      .plate {
        font-size: 1.4rem;
        color: #303030;
      }
    }
  }

  .content {
    padding: 1.6rem;
    background-color: #ffffff;
    border-radius: 10px;

    .list-content {
      // margin-bottom: 1.6rem;

      .item {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        margin-bottom: 1rem;

        label {
          color: #666666;
        }

        div {
          flex: 1;
          text-align: right;
        }
      }

      .item:last-child {
        margin-bottom: 0;
      }
      .item-custom {
        & > div > span {
          font-weight: bold;
        }
        & > div > span:first-child {
          color: #aaaaaa;
          margin: 0 4px 0 0;
        }
        & > div > span:last-child {
          color: #f59a23;
        }
      }
    }

    .card-title {
      display: flex;
      justify-items: center;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 400;
      color: #303030;
      margin-bottom: 1rem;
    }

    .card-title:before {
      content: " ";
      width: 3rem;
      height: 3rem;
      background: url("../../../assets/monthCard/pay-title.png") no-repeat;
      background-size: 100% 100%;
      display: block;
      margin-right: 1rem;
    }

    .choose-price {
      .choose-box {
        padding: 0.5rem;

        .choose-item {
          display: flex;
          background-color: #f6f9fb;
          flex-direction: column;
          height: 7.8rem;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;

          div:first-child {
            font-size: 1.4rem;
            font-weight: 400;
            color: #666666;
            margin-bottom: 5px;
          }

          div:last-child {
            font-size: 2rem;
            font-weight: bold;
            color: #303030;

            label {
              font-size: 1.2rem;
              font-weight: bold;
              color: #303030;
            }
          }
        }

        .chooseed {
          background-color: #2b86ff;

          div:first-child {
            color: #ffffff;
          }

          div:last-child {
            color: #ffffff;

            label {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .pay-btn {
    position: fixed;
    bottom: 2rem;
    height: 4rem;
    left: 1.6rem;
    right: 1.6rem;
    background: #3d90ff;
    border-radius: 12px;
    text-align: center;
    line-height: 4rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
  }
}
/deep/.mui-cell .van-cell {
  padding-right: 0;
  &::after {
    display: none;
  }
}
/deep/.mui-cell .van-cell-group::after {
  display: none;
}

.fixed_btn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 !important;
  height: 5rem;
  line-height: 5rem;
}
</style>
