<template>
  <div>
    <van-popup
      v-model="popupShow"
      position="bottom"
      :close-on-click-overlay="true"
      :close-on-popstate="true"
      closeable
      round
      @closed="closed"
    >
      <div class="popu-content">
        <div class="close-div">
          <div class="left-title"></div>
          <!-- <div class="right-title">4s后关闭</div> -->
        </div>
        <div class="pri-title-div">您需要支付停车费用</div>
        <div class="pri-vlaue">￥{{ totalAmount }}{{ "元" }}</div>
        <div class="pay-type-title-div">请选择支付方式</div>
        <div
          :class="[
            isWxPay === true ? 'pay-status-select' : 'pay-status-default',
          ]"
          @click="wxPay"
        >
          <img
            class="pay-img"
            v-show="!isWxPay"
            src="../../assets/monthCard/wx-default-icon.png"
          />
          <img
            class="pay-img"
            v-show="isWxPay"
            src="../../assets/monthCard/wx-select-icon.png"
          />
          <div class="pay-title-value">微信支付</div>
        </div>
        <div
          v-show="isShowAl"
          :class="[
            isZfbPay === true ? 'pay-status-select' : 'pay-status-default',
          ]"
          @click="zfbPay"
        >
          <img
            class="pay-img"
            v-show="!isZfbPay"
            src="../../assets/monthCard/zfb-default-icon.png"
          />
          <img
            class="pay-img"
            v-show="isZfbPay"
            src="../../assets/monthCard/zfb-select-icon.png"
          />
          <div class="pay-title-value">支付宝</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "payPopup",
  props: {
    show: Boolean,
    totalAmount: String,
    isShowAl: Boolean,
  },
  watch: {
    show(val) {
      this.popupShow = val;
    },
    popupShow(val) {
      if (!val) {
        this.popupShow = false;
        this.$emit("update:show", false);
      }
    },
  },

  data() {
    return {
      popupShow: false,
      isWxPay: false,
      isZfbPay: false,
    };
  },
  methods: {
    closed() {
      this.popupShow = false;
      this.$emit("closed", false);
    },
    //支付宝
    zfbPay() {
      this.popupShow = false;
      this.isWxPay = false;
      this.isZfbPay = true;
      this.$emit("onZfbPay", false);
    },
    //微信
    wxPay() {
      this.isWxPay = true;
      this.isZfbPay = false;
      this.$emit("onWxPay", false);
    },
  },
};
</script>

<style scoped lang="scss">
.popu-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  .close-div {
    padding: 1.6rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .left-title {
    }
    .right-title {
      background: #ffffff;
      border-radius: 2.4rem 2.4rem 0rem 0rem;
      opacity: 1;
    }
  }
  .pri-title-div {
    font-size: 1.4rem;
    background: #ffffff;
    border-radius: 2.4rem 2.4rem 0rem 0rem;
    opacity: 1;
  }
  .pri-vlaue {
    margin-top: 0.8rem;
    font-size: 2rem;
    font-weight: 500;
    color: #3d90ff;
  }
  .pay-type-title-div {
    margin-top: 0.8rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #5b5b5b;
  }
  .pay-title-value {
    font-size: 1.4rem;
    text-align: left;
    margin-left: 10px;
  }
  .pay-img {
    width: 2.6rem;
    height: 2.3rem;
    margin-right: 1rem;
  }
  .pay-status-select {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    text-align: center;
    margin-top: 0.8rem;
    height: 3.4rem;
    background: #3d90ff;
    color: #ffffff;
    border-radius: 0rem 0rem 0rem 0rem;
    opacity: 1;
    align-items: center;
    justify-content: center;
  }
  .pay-status-default {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    text-align: center;
    margin-top: 0.8rem;
    height: 3.4rem;
    background: #ffffff;
    color: #3d90ff;
    border-radius: 0rem 0rem 0rem 0rem;
    opacity: 1;
    align-items: center;
    justify-content: center;
  }
}
</style>