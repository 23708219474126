import { get, post } from '@/util/axios';
//用户详细信息
export const getChargeDetail = (plate) => {
    return get(
        `/api/lecent-park/wechat/temp-park/detail?plate=${plate}`
    )
}

//支付前
export const payBefore = (data) => {
    return get(
        `/api/lecent-park/wechat/temp-park/pay`,
        data
    )
}

//根据车场id获取支付方式
export const getPayWayByChannelId = (params) => {
    return get(
        '/api/lecent-park/parklotpay/getPayWayByParklotId',
        params
    )
}

//查询支付状态
export const OrderStatus = (params) => {
    return get(
        '/api/lecent-park/wechat/temp-park/order-status',
        params
    )
};