<template>
  <div class="container">
    <template v-if="dataList.length > 0">
      <div class="list" v-for="(item, i) in dataList">
        <div class="list-header">
          <div class="ico"></div>
          <div class="address">{{ item.parklotName }}</div>
        </div>
        <div class="list-content">
          <div class="item">
            <label>卡号</label>
            <div>{{ item.cardNo }}</div>
          </div>
          <div class="item">
            <label>续费时长</label>
            <div>{{ item.reChargeTimeLength }}个月</div>
          </div>
          <div class="item">
            <label>支付金额</label>
            <div>{{ item.payAmount }}元</div>
          </div>
          <div class="item">
            <label>支付时间</label>
            <div>{{ item.payTime }}</div>
          </div>
          <div class="item">
            <label>开始时间</label>
            <div>{{ item.startDate }}</div>
          </div>
          <div class="item">
            <label>到期时间</label>
            <div>{{ item.endDate }}</div>
          </div>
        </div>
      </div>
    </template>
    <van-empty
      class="message-image"
      v-else
      :image="require('@/assets/my/car-bg.png')"
      description="暂无缴费记录"
    ></van-empty>
  </div>
</template>

<script>
import { GetCardOrderListByPlate } from "@/api/monthCard";

export default {
  name: "renewRecord",
  created() {
    console.log(this.$route.query.plate);
    this.plate = this.$route.query.plate;
    this.initData();
  },
  data() {
    return {
      plate: "",
      dataList: "",
    };
  },
  mounted() {
    this.WXSDK_PACKAGE.getWxSign();
    this.WXSDK_PACKAGE.shareIndexPage();
  },
  methods: {
    initData() {
      GetCardOrderListByPlate({ plate: this.plate }).then((res) => {
        console.log(res);
        this.dataList = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 1.6rem;
  background: #f6f7fb;
  min-height: calc(100vh - 3.2rem);

  .list {
    padding: 0.3rem;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 1.4rem;
    position: relative;
    .list-header {
      padding: 0 2rem;
      background: #fdfdfe;
      height: 4.5rem;
      font-size: 1.6rem;
      font-weight: 600;
      color: #303030;
      display: flex;
      justify-items: center;
      align-items: center;
      margin-bottom: 0.3rem;
      .ico {
        width: 2.4rem;
        height: 2.4rem;
        background: url("../../../assets/monthCard/address-ico.png") no-repeat;
        background-size: 100%;
        margin-right: 0.8rem;
      }
      .address {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .btn {
        width: 5rem;
        height: 1.7rem;
        border-radius: 3px;
        border: 1px #3d90ff solid;
        font-size: 1.3rem;
        text-align: center;
        line-height: 1.7rem;
        color: #3d90ff;
      }
    }
    .list-content {
      padding: 2rem 2rem;
      background-color: #fafbfd;
      border-radius: 0 0 8px 8px;
      .item {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        margin-bottom: 1rem;
        label {
          color: #666666;
        }
        div {
          flex: 1;
          text-align: right;
        }
      }
      .item:last-child {
        margin-bottom: 0;
      }
    }
    .overdue {
      width: 4rem;
      height: 4rem;
      position: absolute;
      right: 0;
      top: 0;
      background: url("../../../assets/monthCard/overdue.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
