<template>
  <div class="container">
    <div class="content" style="margin-bottom: 1rem">
      <div class="item" @click="showPicker = true">
        <div class="left-div">
          <div class="icon">
            <img src="../../../assets/monthCard/parking-icon.png" />
          </div>
          <div class="info-div">
            <div class="title">{{ data.parklotName }}</div>
            <div class="value">{{ setMealItem.name }}</div>
          </div>
        </div>
        <div class="next-icon">
          <img src="../../../assets/monthCard/down-icon.png" />
        </div>
      </div>
    </div>
    <plateKeyBoard v-model="licenseNumber" style="margin-bottom: 1rem" />
    <div class="content" style="margin-bottom: 1rem; margin-tom: 10px">
      <div class="input-box">
        <div class="input-item">
          <div class="input-name">
            <div class="mark">*</div>
            月卡所有人:
          </div>
          <input
            class="input-value"
            style="border-style: none"
            v-model="userNameValue"
            placeholder="请输入月卡所有人 "
            type="text"
          />
        </div>
        <div class="line"></div>
        <div class="input-item">
          <div class="input-name">
            <div class="mark">*</div>
            电 话:
          </div>
          <input
            style="border-style: none"
            class="input-value"
            v-model="phoneValue"
            placeholder="请输入电话"
            type="text"
            maxlength="12"
          />
          <!-- <div class="input-value">2212</div> -->
        </div>
        <div class="line"></div>
        <div class="input-item">
          <div class="input-name">
            <div class="mark">*</div>
            备 注:
          </div>
          <input
            style="border-style: none"
            class="input-value"
            v-model="remarkValue"
            placeholder="请输入备注"
            type="text"
          />
        </div>
        <div class="line"></div>
      </div>
      <!-- <div class="item">
        <div class="left-div">
          <div class="info-div">
            <div class="input">
              <van-field
                label="月卡所有人"
                v-model="userNameValue"
                style="padding-left: 0"
                placeholder="请输入月卡所有人"
              />
            </div>

            <div class="input">
              <van-field
                label="联系电话"
                v-model="phoneValue"
                placeholder="请输入电话"
                style="padding-left: 0"
              />
            </div>
            <div class="input">
              <van-field
                v-model="remarkValue"
                placeholder="请输入备注"
                style="padding-left: 0"
              />
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="content">
      <div class="choose-price">
        <van-row>
          <template v-for="(item, i) in setMealItem.monthPrice">
            <van-col span="6">
              <div class="choose-box" @click="chooseed(i, item)">
                <div
                  class="choose-item"
                  :class="chooseIndex == i ? 'chooseed' : ''"
                >
                  <div>{{ item.monthName }}</div>
                  <div>￥{{ item.totalPrice }}</div>
                </div>
              </div>
            </van-col>
          </template>
        </van-row>
      </div>
      <div class="list-content" style="margin-bottom: 0">
        <div class="item">
          <label>自定义续费</label>
          <div>
            <van-stepper
              v-model="stepperDefault"
              min="0"
              @change="stepperChange"
            />
          </div>
        </div>
        <div class="item">
          <label>续费金额</label>
          <div>
            <div class="div-pri">
              {{ totalAmount != "" ? "¥" : "" }}{{ totalAmount }}{{ "元" }}
            </div>
          </div>
        </div>
      </div>
      <div class="time-select">
        <div>
          <div class="item">
            <div class="left-div">
              <div class="icon">
                <img src="../../../assets/monthCard/start-time-icon.png" />
              </div>
              <div class="info-div">
                <div class="title">月卡开始日期</div>
                <div class="input">{{ data.startTime }}</div>
              </div>
            </div>
          </div>
          <div class="item" style="margin-top: 10px">
            <div class="left-div">
              <div class="icon">
                <img src="../../../assets/monthCard/start-time-icon.png" />
              </div>
              <div class="info-div">
                <div class="title">月卡结束日期</div>
                <div class="input">{{ newEndDate }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            src="../../../assets/monthCard/start-end-time-icon.png"
            style="margin-right: 3rem"
          />
        </div>
      </div>
    </div>
    <payPopup
      :show="show"
      :totalAmount="totalAmount"
      :isShowAl="orderInfo.scanAliMimiId == '' ? false : true"
      @closed="show = false"
      @onZfbPay="zfbPay"
      @onWxPay="wxPay"
    />
    <div class="pay-btn" v-show="isShowPay" @click="onCreateMonthCard">
      确认开通
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="setMealItems"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  onlineOpenCategories,
  getRenewTimeDuration,
  onlineNewMonthlyCardPay,
  cardPay,
} from "@/api/monthCard";
import plateKeyBoard from "@/components/plateKeyboard";
import payPopup from "@/components/payPopup";
import moment from "moment";
import { Notify } from "vant";
export default {
  components: {
    plateKeyBoard,
    payPopup,
  },
  created() {
    this.parklotId = this.$route.query.parklotId;
    if (this.$route.query.userId != "") {
      this.userId = this.$route.query.userId;
    }
    this.initData();
  },
  data() {
    return {
      licenseNumber: "",
      setMealItem: "", //选中的套餐
      setMealItems: [],
      userNameValue: "",
      phoneValue: "",
      remarkValue: "",
      userId: "",
      optTime: 0,
      loopTime: 3000, //轮循周期
      optTimeMax: 180, //轮训时间停止时间
      parklotId: "",
      show: false, //是否展示支付弹框
      cardId: "", //车辆id
      orderId: "", //订单号
      btnLoading: false,
      totalAmount: "", //金额
      showPicker: false, //是否显示下拉选择器
      stepperChangeNum: false,
      newEndDate: "", //结束时间
      id: "",
      stepperDefault: "0", //月数
      isShowPay: false, //是否展示支付
      data: {},
      chooseData: {},
      chooseIndex: -1,
      interval: null,
      newTime: {
        endDate: "",
        startDate: "",
      },
      //开卡后的支付信息
      orderInfo: "",
    };
  },
  methods: {
    //验证电话号码格式
    validatePhone(val) {
      var isPhone = /^1[0-9]{10}$/g;
      // var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/; //手机号码
      var isMob = /^0?1[3|4|5|8][0-9]\d{8}$/; // 座机格式  区号之后用'-'隔开
      if (isMob.test(val) || isPhone.test(val)) {
        return true;
      } else {
        return false;
      }
    },

    stopClearInterval() {
      this.optTime = 0;
      clearInterval(this.interval);
      this.$router.replace("/payRes");
    },
    //定时 开始
    startSetInterval() {
      if (this.interval != null) {
        this.optTime = 0;
        clearInterval(this.interval);
      }
      this.interval = setInterval(this.cardPay, this.loopTime);
    },
    //查询支付接口
    cardPay() {
      this.optTime++;
      if (this.optTime == this.optTimeMax) {
        clearInterval(this.interval);
      }
      let jsonData = { orderId: this.orderId };
      cardPay(jsonData).then((res) => {
        if (res.code == 200 && res.success == true && res.data == true) {
          this.stopClearInterval();
        }
      });
    },

    //开通月卡点击
    onCreateMonthCard() {
      if (this.licenseNumber == "") {
        Notify({ type: "warning", message: "请输入车牌" });
        return;
      }
      if (this.userNameValue == "") {
        Notify({ type: "warning", message: "请输入月卡所有人" });
        return;
      }
      if (this.phoneValue == "") {
        Notify({ type: "warning", message: "请输入电话" });
        return;
      }

      if (!this.validatePhone(this.phoneValue)) {
        Notify({ type: "warning", message: "请输入正确的电话" });
        return;
      }
      if (this.stepperDefault == 0) {
        Notify({ type: "warning", message: "月数不能为0" });
        return;
      }

      let jsonData = {
        ownerName: this.userNameValue,
        phone: this.phoneValue,
        plate: this.licenseNumber,
        remark: this.remarkValue,
        cardStartTime: this.data.startTime,
        parklotId: this.parklotId,
        categoryId: this.setMealItem.id,
        renewalFeeNum: this.stepperDefault,
        renewalFeeMount: this.totalAmount,
        userId: this.userId,
      };
      console.log("jsonData", jsonData);
      onlineNewMonthlyCardPay(jsonData).then((res) => {
        console.log(res);
        this.orderInfo = res.data;
        this.orderId = res.data.orderId;
        this.show = true;
      });
    },
    //获取套餐
    initData() {
      onlineOpenCategories({ parklotId: this.parklotId }).then((res) => {
        console.log(res);
        this.data = res.data;
        if (res.data.cardCategories.length > 0) {
          this.isShowPay = true;
          this.setMealItem = res.data.cardCategories[0];
          for (let i = 0; i < res.data.cardCategories.length; i++) {
            this.setMealItems.push(res.data.cardCategories[i].name);
          }
          this.stepperChange(1);
        }
      });
    },
    onConfirm(value, index) {
      this.showPicker = false;
      this.setMealItem = this.data.cardCategories[index];
      this.stepperChangeNum = false;
      this.newEndDate = "";
      this.chooseIndex = -1;
      this.stepperDefault = 0;
      console.log("index", index);
      this.stepperChange(1);
    },
    onChange(picker, value, index) {},
    onCancel() {
      this.showPicker = false;
      // Toast("取消");
    },
    //支付宝支付
    zfbPay() {
      this.startSetInterval();
      this.show = false;
      let param =
        "type=card&orderId=" + this.orderId + "&userId=" + this.userId;
      let appId = this.orderInfo.scanAliMimiId;
      let page = "pages/jump-pay/index";

      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.jstwebview.startAliMini(appId, page, param);
      }
      if (isIOS) {
        let paramJson = {
          type: "card",
          orderId: this.orderId,
          userId: this.userId,
        };
        let json = {
          appId: appId,
          page: page,
          param: paramJson,
        };
        console.log(JSON.stringify(json));

        window.webkit.messageHandlers.startAliMini.postMessage(
          JSON.stringify(json)
        );
      }
    },
    //微信支付
    wxPay() {
      this.startSetInterval();
      this.show = false;
      let param = "type=card&orderId=" + this.orderId + "&userId=" + "121212";
      let appId = this.orderInfo.scanMimiId;
      let userName = this.orderInfo.scanMimiOriginalId;

      // let appId = "wxa8f99e190675abc0";
      // let userName = "gh_d86b1fb84d93";

      let path = "pages/jump-pay/index?" + param;
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.jstwebview.startWxMini(appId, userName, path);
      }
      if (isIOS) {
        let json = {
          userName: userName,
          path: path,
        };
        console.log("json", json);
        window.webkit.messageHandlers.startWxMini.postMessage(
          JSON.stringify(json)
        );
      }
    },
    //初始化日期
    initDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    //步进器变化监听
    stepperChange(event) {
      for (let i = 0; i < this.setMealItem.monthPrice.length; i++) {
        if (this.setMealItem.monthPrice[i].month == event) {
          this.chooseIndex = i;
        }
      }
      this.initEndTime(event);
    },
    //缴费结束时间
    initEndTime(event) {
      this.stepperDefault = event;

      if (event != 0) {
        let jsonData = {
          categoryId: this.setMealItem.id,
          monthNum: event,
          nextStartTime: this.data.startTime,
        };
        console.log("jsonData", jsonData);
        getRenewTimeDuration(jsonData).then((res) => {
          this.newEndDate = res.data.endDate;
          this.totalAmount = res.data.totalAmount;
          // this.changRadio();
        });
      } else {
        this.stepperChangeNum = false;
        this.newEndDate = "";
        this.chooseIndex = -1;
        this.totalAmount = 0;
      }
    },

    //续费月份点击
    chooseed(i, item) {
      this.chooseIndex = i;
      this.initEndTime(item.month);
    },
    //下单
    renewalFee() {
      if (this.stepperDefault == 0) {
        Notify({ type: "warning", message: "缴费月数不能为0" });
        return;
      }

      let jsonData = {
        cardId: this.cardId,
        renewalFeeNum: this.stepperDefault,
        renewalFeeMount: this.totalPri,
        reChargeType: "1",
        userId: this.userId,
        returnUrl: "www.baidu.com",
      };

      renewalFee(jsonData).then((res) => {
        console.log(res);
        this.orderId = res.data;
        this.show = true;
        // this.changRadio();
      });
    },
  },
};
</script>

<style scoped lang="scss">
>>> .van-button--round {
  border-radius: 1rem !important;
}
.container {
  padding: 1.6rem 1.6rem 1rem 1.6rem;
  min-height: calc(100vh - 3.2rem);
  background: #f6f7fb;

  .content {
    padding: 1rem 1rem;
    background-color: #ffffff;
    border-radius: 10px;

    .input-box {
      display: flex;
      flex-direction: column;

      .input-item {
        flex-direction: row;
        align-items: center;
        text-align: center;
        display: flex;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        .input-name {
          display: flex;
          flex-direction: row;
          text-align: center;
          font-size: 1.6rem;
          font-weight: 500;
          color: #303030;
          margin-bottom: 0.5rem;
          .mark {
            margin-top: 0.2rem;
            color: #ff0000;
            text-align: center;
          }
        }
        .input-value {
          line-height: 40px;
          margin-left: 10px;
          font-size: 1.6rem;
          font-weight: 500;
          color: #303030;
          margin-bottom: 0.5rem;
        }
      }
      .line {
        height: 0.05rem;
        background: #999999;
      }
    }

    .time-select {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    .list-content {
      margin-bottom: 1.6rem;

      .item {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        align-items: center;
        margin-bottom: 1rem;

        label {
          font-size: 1.4rem;
          font-weight: 500;
          color: #9a9a9a;
        }

        div {
          flex: 1;
          text-align: right;
          font-size: 1.2rem;
          font-weight: 500;
          color: #9a9a9a;
        }
        //金额
        .div-pri {
          font-size: 2.2rem;
          font-weight: 500;
          color: #2376e5;
        }
      }

      .item:last-child {
        margin-bottom: 0;
      }
    }
    .choose-price {
      .choose-box {
        .choose-item {
          display: flex;
          background-color: #f6f9fb;
          flex-direction: column;
          width: 6.2rem;
          height: 6.1rem;
          border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
          opacity: 1;
          align-items: center;
          justify-content: center;
          margin-bottom: 0.5rem;

          div:first-child {
            font-size: 1rem;
            font-weight: 400;
            color: #676767;
          }

          div:last-child {
            font-weight: bold;
            color: #313131;
            font-size: 1.4rem;
            font-weight: 500;
          }
        }

        .chooseed {
          background-color: #2b86ff;

          div:first-child {
            font-size: 1rem;
            font-weight: 400;
            color: #ffffff;
          }

          div:last-child {
            color: #ffffff;
            font-weight: bold;
            font-size: 1.4rem;
            font-weight: 500;
          }
        }
      }
    }
    .item {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      .left-div {
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 1.3rem;
        }
        .info-div {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          text-align: left;
          .line {
            background: #919191;
            height: 1rem;
          }

          .title {
            font-size: 1.6rem;
            font-weight: 500;
            color: #303030;
            margin-bottom: 0.5rem;
          }
          .value {
            min-height: 20px;
            font-size: 1.6rem;
            font-weight: 500;
            color: #3d90ff;
          }
          .input {
            font-size: 1.4rem;
            font-weight: 500;
            color: #303030;
          }
        }
      }
      .next-icon {
      }
    }
  }

  .pay-btn {
    position: fixed;
    bottom: 2rem;
    height: 4rem;
    left: 1.6rem;
    right: 1.6rem;
    background: #3d90ff;
    border-radius: 12px;
    text-align: center;
    line-height: 4rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
  }
}
</style>
