<template>
  <div class="container">
    <article class="content">
      <div class="plate-header">
        <!--      <div class="plate-position">-->
        <!--        <div>当前位置： </div>-->
        <!--        <div>中天商务广场停车场</div>-->
        <!--      </div>-->
      </div>
      <div class="card-box">
        <div class="title">
          <span>车辆出场</span>
          <span>请停车用户输入车牌出场</span>
        </div>
        <div>
          <plateKeyboard v-model="licenseNumber" :oldPlate="oldPlateShow" />
          <!-- <div class="number_box" @click="openKeyboard">
            <span class="first-card">{{ licenseNumber.split('')[0] }}</span>
            <span>{{ licenseNumber.split('')[1] }}</span>
            <i class="plate-ico"></i>
            <span>{{ licenseNumber.split('')[2] }}</span>
            <span>{{ licenseNumber.split('')[3] }}</span>
            <span>{{ licenseNumber.split('')[4] }}</span>
            <span>{{ licenseNumber.split('')[5] }}</span>
            <span>{{ licenseNumber.split('')[6] }}</span>
            <span class="new-card">{{ licenseNumber.split('')[7] }}
              <div class="tips-card">新能源</div>
            </span>
          </div> -->
        </div>
        <div class="history">
          <span>历史车牌</span><span @click="fillPlate">{{oldPlate}}</span>
        </div>
      </div>
      <!-- <div class="tips">
      <div class="tip-title">温馨提示</div>
      <div class="tip-content">
        <p>1、停车15分钟不收费，超过15分钟不足1小时，按照1小时收费。</p>
        <p>2、军营、消防、救护、抢险、及政府规定车辆均不收费。</p>
        <p>3、缴费后，请在15分钟内离场，无牌车和临牌车出场请扫出场二维码。</p>
      </div>
      <div class="charge-info">
        <div class="time">
          <div>07:00-18:00</div>
          <div>18:00-07:00</div>
        </div>
        <div class="charge">
          <div>5元/小时</div>
          <div>3元/小时,12元封顶</div>
        </div>
      </div>
    </div> -->
      <ad :show.sync="adShow" :src="adUrl" :clickText="clickText" @handleClick="handleClick">
        <template v-slot:content>
          <van-row class="list-item" v-for="(item, j) in dataList" :key="j">
            <van-col span="6">
              <div class="money" v-if="item.couponType == 1">
                {{ item.reduceAmount }}<span>元</span>
              </div>
              <div class="money" v-else-if="item.couponType == 2">
                {{ item.discountAmount }}<span>折</span>
              </div>
              <div class="money" v-else-if="item.couponType == 3">
                {{ item.reduceHour }}<span>小时</span>
              </div>
            </van-col>
            <van-col span="18">
              <div class="title">{{ item.couponName }}</div>
              <div class="descrip" v-if="item.couponType == 1">
                满{{ item.fullAmount }}元使用
              </div>
            </van-col>
          </van-row>
        </template>
      </ad>
    </article>
    <div class="footer">
      <van-button :disabled="submitStatus" :loading="btnLoading" @click="searchCharge" class="class-button" block type="info">查询</van-button>
    </div>
  </div>
</template>

<script>
import { apiListByTempPay, apiListByTempPayNotLogin } from '@/api/coupon/index'
import ad from "@/components/ad/ad"
import { getAccessToken } from '@/util/store'
import plateKeyboard from "@/components/plateKeyboard"
import { getChargeDetail } from '@/api/searchPlate/index'

export default {
  components: { ad, plateKeyboard },
  data() {
    return {
      dataList: [],
      adUrl: require('../../assets/adver/ad-pay.png'),
      adShow: false,
      licenseNumber: '贵',
      btnLoading: false,
      submitStatus: true,
      oldPlate: window.localStorage.getItem('oldPlate'),
      oldPlateShow: '',
      clickText: "立即使用"
    }
  },
  watch: {
    licenseNumber(val, old) {
      this.submitStatus = val.length < 7 ? true : false
    },
    dataList(val) {
      if (!val.length) {
        this.adShow = false;
        return;
      }
      this.adShow = true;
    }
  },
  computed: {
  },
  mounted() {
    //弹出优惠券广告
    //只在第一次进入的时候弹优惠券
    if (this.$route.query.isInit && this.$route.query.isInit == 1) {
      if (getAccessToken()) {
        this.clickText = "立即使用"
        this.getListByTempPay();
        return;
      }
      this.getListByTempPayNotLogin();
      this.clickText = "马上去领取";
    }
  },
  methods: {
    handleClick() {
      // 如果用户已经登录，直接调用获取优惠券的接口，否则调用只展示优惠券的接口
      if (getAccessToken()) {
        this.adShow = false;
        //   this.$router.push({
        //     path:"/couponList/getList",
        //     query:{
        //       isPay:0
        //     }
        // });
        return;
      }
      this.$router.push({
        path: "/couponList",
        query: {
          isPay: 0
        }
      })
    },
    getListByTempPayNotLogin() {
      apiListByTempPayNotLogin().then(res => {
        if (res.success) {
          this.dataList = res.data;
        }
      }).catch(err => {
        console.log('apiListByTempPay::', err)
      })
    },
    getListByTempPay() {
      apiListByTempPay(window.localStorage.getItem("openId")).then(res => {
        if (res.success) {
          this.dataList = res.data;
        }
      }).catch(err => {
        console.log('apiListByTempPay::', err)
      })
    },
    fillPlate() {
      this.oldPlateShow = this.oldPlate;
      // setTimeout(() => {
      //   this.oldPlateShow = '';
      // }, 300)
    },
    openKeyboard() {
      this.$refs.keyboard.isShow = true;
      this.$refs.keyboard.licenseNumber = this.licenseNumber;
      this.$refs.keyboard.type = false;
    },
    searchCharge() {
      this.btnLoading = true
      getChargeDetail(this.licenseNumber).then(res => {
        window.localStorage.setItem('oldPlate', this.licenseNumber);
        this.btnLoading = false
        this.$router.push({ path: '/parkingRecord', query: { plate: this.licenseNumber, openId: localStorage.getItem('openId') } })
      }).catch(() => {
        this.btnLoading = false
      })
      // window.localStorage.setItem('oldPlate', this.licenseNumber);
      // this.$router.push({ path: '/parkingRecord', query: { plate: this.licenseNumber, openId: localStorage.getItem('openId') } })
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  .plate-header {
    text-align: left;
    height: 18.83rem;
    position: relative;
    .plate-position {
      margin-left: 1rem;
      position: relative;
      div {
        font-weight: 400;
        color: #666666;
        margin-left: 2rem;
        :first-child {
          font-size: 1rem;
        }
        :last-child {
          font-size: 1.4rem;
        }
      }
      &:before {
        position: absolute;
        content: "";
        margin-top: 0.5rem;
        width: 1.08rem;
        height: 2.08rem;
        background: url("../../assets/searchPlate/plate-location.png") no-repeat
          top left;
        background-size: 100% 100%;
      }
    }
    &:before {
      position: absolute;
      left: 50%;
      margin-left: -15rem;
      content: "";
      width: 30rem;
      height: 18.83rem;
      background: url("../../assets/searchPlate/plate-img.png") no-repeat top
        left;
      background-size: 100% 100%;
    }
  }
  .content {
    height: calc(100vh - 7rem);
    overflow-y: scroll;
    padding-top: 2rem;
    .card-box {
      background: white;
      padding: 1.5rem 2rem;
      .title {
        text-align: left;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        span:first-child {
          font-size: 1.33rem;
          font-weight: 500;
          color: #303030;
        }
        span:last-child {
          font-size: 1.17rem;
          font-weight: 400;
          color: #666666;
        }
      }
      .history {
        text-align: left;
        margin-top: 1.5rem;
        span:first-child {
          font-size: 1.17rem;
          font-weight: 400;
          color: #b0b0b0;
          margin-right: 1rem;
        }
        span:last-child {
          font-size: 1.17rem;
          font-weight: 400;
          color: #3d90ff;
        }
      }
    }
    .tips {
      text-align: left;
      margin: 1.5rem;
      background: #ebedf2;
      padding: 1.5rem;
      border-radius: 0.6rem;
      .tip-title {
        font-size: 1.17rem;
        font-weight: 400;
        color: #303030;
      }
      .tip-content {
        font-size: 1rem;
        font-weight: 400;
        color: #666666;
        margin: 1rem 0;
        p {
          margin: 0;
        }
      }
      .charge-info {
        border-radius: 0.4rem;
        background: white;
        .time,
        .charge {
          display: flex;
          div {
            text-align: center;
            width: 50%;
            height: 3.33rem;
            line-height: 3.33rem;
            font-size: 1rem;
            font-weight: 400;
            color: #303030;
            &:first-child {
              position: relative;
              &:after {
                position: absolute;
                content: "";
                right: 0;
                height: 3.33rem;
                width: 1px;
                background: #e3e5e8;
              }
            }
          }
        }
        .charge {
          background: #f6f7fb;
        }
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    margin: 0 1rem 1rem;
    width: calc(100vw - 2rem);
    /deep/ .class-button {
      height: 3.67rem;
    }
  }
  .number_box {
    height: 4.45rem;
    border-radius: 1rem;
    text-align: center;
    line-height: 4.45rem;
    font-size: 2.1rem;
    display: flex;

    .first-card {
      background: url("../../assets/monthCard/first-card.png") no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
    }

    .new-card {
      background-color: #69c923;
      color: #ffffff;
      position: relative;

      .tips-card {
        position: absolute;
        font-size: 1.2rem;
        top: -30px;
        color: #69c923;
        width: 4rem;
        left: -3px;
      }
    }
  }

  .number_box span {
    border: 1px #e3e3e3 solid;
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 5px;
  }

  .number_box .plate-ico {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #333333;
    margin-top: 15px;
    margin-right: 5px;
  }
  .card {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 1.5rem;
    margin: 0.5rem 0;
    .card-price {
      display: flex;
      align-items: center;
      color: #ff2e2e;
      &-num {
        font-size: 1.8rem;
      }
      &-text {
        font-size: 1.4rem;
      }
    }
    .card-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-title {
        font-weight: bold;
        color: #303030;
        font-size: 1.4rem;
      }
      &-detail {
        font-size: #999999;
        font-size: 1.2rem;
      }
    }
  }
}
</style>
