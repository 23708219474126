import { get, post } from '@/util/axios';


/**
 *
 * @param 根据车牌号查询月卡列表
 * @returns {*}
 */
export const GetCardListByNum = (params) => {
    return get(
        '/api/lecent-park/user-side/card/list',
        params
    )
};
/**
 *
 * @param 根据id获取续费详情
 * @returns {*}
 */
export const GetCardDetail = (params) => {
    return get(
        '/api/lecent-park/user-side/card/detail',
        params
    )
};


/**
 *
 * @param 查询月卡最新续费时间
 * @returns {*}
 */
export const GetNewestTimeDuration = (params) => {
    return get(
        '/api/lecent-park/user-side/card/getNewestTimeDuration',
        params
    )
};

/**
 *
 * @param 续费唤起微信支付
 * @returns {*}
 */
export const RenewalFee = (params) => {
    return post(
        '/api/lecent-park/user-side/card/renewalFee',
        params
    )
};

/**
 *
 * @param 查询缴费记录
 * @returns {*}
 */
export const GetCardOrderListByPlate = (params) => {
    return get(
        '/api/lecent-park/user-side/card/getCardOrderListByPlate',
        params
    )
};
/**
 *
 * @param 查询缴费记录
 * @returns {*}
 */
export const OrderStatus = (params) => {
    return get(
        '/api/lecent-park/user-side/payCallback',
        params
    )
};

/**
 *
 * @param params
 * @returns 查询支付方式
 */

export const getPayWayByChannelId = (params) => {
    return get(
        '/api/lecent-park/parklotpay/getPayWayByParklotId',
        params
    )
};

/**
 *
 * @param 查询月卡信息
 * @returns {*}
 */
export const payDetail = (params) => {
    return get(
        '/api/open-api/park/open-h5/card/v1/detail',
        params
    )
};

/**
 *
 * @param 月卡下单
 * @returns {*}
 */
export const renewalFee = (params) => {
    console.log(params);
    return post(
        '/api/open-api/park/open-h5/card/v1/renewalFee',
        params
    )
};

/**
 *
 * @param 查询车牌是否已开通月卡
 * @returns {*}
 */
export const checkPlate = (params) => {
    console.log(params);
    return post(
        '/api/open-api/park/open-h5/card/v1/checkPlate',
        params
    )
};

/**
 *
 * @param 查询车场套餐
 * @returns {*}
 */
export const onlineOpenCategories = (params) => {
    console.log(params);
    return get(
        '/api/open-api/park/open-h5/card/v1/onlineOpenCategories',
        params
    )
};


/**
 *
 * @param 查询套餐开始结束日期
 * @returns {*}
 */
export const getRenewTimeDuration = (params) => {
    console.log(params);
    return get(
        '/api/open-api/park/open-h5/card/v1/getRenewTimeDuration',
        params
    )
};

/**
 *
 * @param 开卡
 * @returns {*}
 */
export const onlineNewMonthlyCardPay = (params) => {
    console.log(params);
    return post(
        '/api/open-api/park/open-h5/card/v1/onlineNewMonthlyCardPay',
        params
    )
};

/**
 *
 * @param 查询支付结果
 * @returns {*}
 */
export const cardPay = (params) => {
    console.log(params);
    return get(
        '/api/open-api/park/open-h5/card/v1/paySuccess',
        params
    )
};

