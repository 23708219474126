<template>
  <div class="container">
    <div class="no-car" v-if="false">
      <img alt="" src="../../../assets/my/car-bg.png" />
      <div class="desc">您还没有绑定车辆</div>
      <div style="margin-top: 1.6rem">
        <van-button type="info">新增车辆</van-button>
      </div>
    </div>
    <div class="form" v-else>
      <article class="content">
        <h3 class="tip">车牌：</h3>
        <plateKeyboard v-model="licenseNumber" />
      </article>
      <div>
        <div class="title">车辆信息</div>
        <div class="form-container">
          <div>
            <div class="pick-desc">车辆品牌</div>
            <div>
              <van-field v-model="form.plateBrand" is-link readonly label="" placeholder="车辆品牌" @click="carbrandShow = true" />
            </div>
          </div>
          <div>
            <div class="pick-desc">备注</div>
            <div>
              <van-field v-model="form.remark" label="" placeholder="备注(非必填)" />
            </div>
          </div>
          <div>
            <div class="pick-desc">关系选择</div>
            <div class="relation-list">
              <div :class="['item', { active: form.tag == j + 1 }]" v-for="(item, j) in relationColumns" :key="j" @click="onConfirmRelation(item)">
                {{ item.text }}
              </div>
            </div>
          </div>
          <!-- <div>
            <div class="pick-desc">ETC开通情况</div>
            <div>
              <van-field
                  v-model="form.etcName"
                  is-link
                  readonly
                  label=""
                  placeholder="请选择ETC开通情况"
                  @click="ETCPopup = true"
              />
            </div>
          </div> -->
        </div>
      </div>
      <div>
        <Debounce :time="1000" :isDebounce="2">
          <van-button :disabled="bindStatus" type="info" style="width: 100%" @click="bind">{{ eidtSource ? "保存" : "立即绑定" }}</van-button>
        </Debounce>
      </div>

      <!-- <van-popup v-model="carTypePopup" round position="bottom">
        <van-picker
            title="请选择车辆品牌"
            show-toolbar
            :columns="carTypeColumns"
            @confirm="onConfirmCarType"
            @cancel="carTypePopup = false"
        />
      </van-popup> -->
      <!-- 
      <van-popup v-model="relationPopup" round position="bottom">
        <van-picker
            title="请选择关系"
            show-toolbar
            :columns="relationColumns"
            @confirm="onConfirmRelation"
            @cancel="relationPopup = false"
        />
      </van-popup> -->
      <van-popup v-model="ETCPopup" round position="bottom">
        <van-picker title="ETC开通情况" show-toolbar :columns="ETCColumns" @confirm="onConfirmETC" @cancel="ETCPopup = false" />
      </van-popup>
      <keyboard ref="keyboard" v-model="licenseNumber" />
    </div>

    <!-- 车辆品牌侧边弹窗 -->
    <van-popup class="carpopup" v-model="carbrandShow" position="right" :style="{ width: '80%', height: '100%' }">
      <form action="/">
        <van-search style="height: 5rem; box-sizing: border-box" shape="round" v-model="searchValue" show-action placeholder="输入车品牌搜索" @search="onSearch" />
      </form>
      <van-index-bar ref="vanBar" :index-list="alphabet" style="height: calc(100% - 5rem); box-sizing: border-box">
        <template v-for="(item, j) in carTypeColumns">
          <van-index-anchor :index="item.initial" :key="j" />
          <template v-for="(text, i) in item.data">
            <van-cell @click="onConfirmCarType(text)" :title="text.firstName" :value="text.chineseName" :key="i" />
          </template>
        </template>
      </van-index-bar>
    </van-popup>

    <!-- 绑定车牌后根据要求弹出优惠券 -->
    <van-dialog v-model="couponShow" title="已成功绑定车辆" show-cancel-button confirmButtonText="查看优惠券" confirmButtonColor="green" @confirm="seeCouponFun" @cancel="cancelCouponFun">
      <img src="../../../assets/coupon/success.png" />
      <div class="gongxi">恭喜您获得 <span>{{couponListData.length}}</span> 张优惠券</div>
    </van-dialog>
    <ad :show.sync="adShow" :src="adUrl" clickText="马上去领取" @handleClick="goGetList">
      <template v-slot:content>
        <van-row class="list-item" v-for="(item, j) in dataList" :key="j">
          <van-col span="6">
            <div class="money" v-if="item.couponType == 1">
              {{ item.reduceAmount }}<span>元</span>
            </div>
            <div class="money" v-else-if="item.couponType == 2">
              {{ item.discountAmount }}<span>折</span>
            </div>
            <div class="money" v-else-if="item.couponType == 3">
              {{ item.reduceHour }}<span>小时</span>
            </div>
          </van-col>
          <van-col span="18">
            <div class="title">{{ item.couponName }}</div>
            <div class="descrip" v-if="item.couponType == 1">
              满{{ item.fullAmount }}元使用
            </div>
          </van-col>
        </van-row>
      </template>
    </ad>
  </div>
</template>


<script>
import { getAccessToken } from '@/util/store'
import { apiListByTempPay } from '@/api/coupon/index'
import { apiBindCarCouponList } from '@/api/coupon'
import ad from "@/components/ad/ad"
import plateKeyboard from "@/components/plateKeyboard";

import {
  getCarTypeList,
  bindCar,
  updateCar,
  carbrandList,
  plateBind,
} from "@/api/my/myCar";

export default {
  name: "index",
  components: { ad, plateKeyboard },
  data() {
    return {
      adUrl: require('../../../assets/adver/ad-input-car.png'),
      adShow: false,
      dataList: [],
      eidtSource: false,
      resCarTypeList: [],
      carbrandShow: false,
      licenseNumber: "贵",
      searchValue: "",
      fieldValue: "",
      relationPopup: false,
      bindStatus: true,
      ETCPopup: false,
      carTypeColumns: [],
      relationColumns: [
        { text: "我的车", id: 1 },
        { text: "亲戚的车", id: 2 },
        { text: "朋友的车", id: 3 },
      ],
      ETCColumns: [
        { text: "未开通", open: 0 },
        { text: "已开通", open: 1 },
      ],
      form: {
        plate: "",
        tag: "",
        tagName: "",
        plateBrand: "",
        etcEnable: "",
        // etcName:'',
        remark: "",
      },
      alphabet: [],
      couponShow: false,
      couponListData: []
    };
  },
  created() {
    this.getCarList();
  },
  computed: {},
  watch: {
    licenseNumber(val, old) {
      this.bindStatusFun();
    },
    dataList(val) {
      if (!val.length) {
        this.adShow = false;
        return;
      }
      this.adShow = true;
    }
  },
  mounted() {
    let _data = this.$route.query.eidtCarNews;
    // 判断是否有参
    if (typeof _data != "undefined") {
      this.eidtSource = true;
      this.form = _data;
      this.licenseNumber = _data.plate;
    }
    // 已登录，打开弹窗
    // if(getAccessToken()){
    //   this.getListByTempPay();
    // }
    // this.getListByTempPay();
  },
  methods: {
    getListByTempPay() {
      apiListByTempPay(window.localStorage.getItem("openId")).then(res => {
        if (res.success) {
          this.dataList = res.data;
        }
      }).catch(err => {
        console.log('apiListByTempPay::', err)
      })
    },
    goGetList() {
      if (!getAccessToken()) {
        this.$router.push('/couponList');
        return;
      }
      this.$router.push({
        path: "/couponList/getList",
        query: {
          // true代表从支付成功页跳转进去
          isPay: 0
        }
      })
    },
    openKeyboard() {
      if (this.eidtSource) return;
      this.$refs.keyboard.isShow = true;
      this.$refs.keyboard.licenseNumber = this.licenseNumber;
      this.$refs.keyboard.type = false;
    },
    // 选择车品牌的值

    onConfirmCarType(event) {
      if (!event) return;
      this.carbrandShow = false;
      this.form.plateBrand = event.chineseName;
      this.form.plateBrand = event.text;
      this.bindStatusFun();
    },
    onConfirmRelation(event) {
      //   this.relationPopup = false
      this.form.tag = event.id;
      this.form.tagName = event.text;
      this.bindStatusFun();
    },
    onConfirmETC(event) {
      this.ETCPopup = false;
      this.form.etcEnable = event.open;
      // this.form.etcName = event.text
      this.bindStatusFun();
    },

    getCarList() {
      getCarTypeList().then((res) => {
        this.resCarTypeList = res.data;
        res.data.forEach((item) => {
          item.text = item.chineseName;
          item["firstName"] = item.chineseName.substr(0, 1);
        });
        this.carTypeColumns = this.jsonGroup(res.data, "initial");

        let alphabet = res.data;
        this.alphabet = [...new Set(alphabet.map((item) => item.initial))];
        // console.log(this.alphabet);
      });
    },

    // 对json进行分组
    /**
     * json  json数组
     * key   要分组的字段名
     */
    jsonGroup(json, key) {
      var map = {},
        dest = [];
      for (var i = 0; i < json.length; i++) {
        var ai = json[i];
        if (!map[ai[key]]) {
          //依赖分组字段可自行更改！
          dest.push({
            [key]: ai[key], //依赖分组字段可自行更改！
            data: [ai],
          });
          map[ai[key]] = ai; //依赖分组字段可自行更改！
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj[key] == ai[key]) {
              //依赖分组字段可自行更改！
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      return dest;
    },
    // 品牌搜索
    onSearch(val) {
      let filterList = this.resCarTypeList.filter((item) => {
        if (item.chineseName.indexOf(val) > -1) {
          return item;
        }
      });
      if (filterList.length > 0) {
        this.$refs.vanBar.scrollTo(filterList[0].initial);
      } else {
        this.$msg("搜索无效值");
        this.searchValue = "";
      }
    },
    // 绑定车辆后获得优惠券
    getBindCarCoupon() {
      apiBindCarCouponList().then(res => {
        let couponList = res.data
        this.couponListData = couponList
        if (couponList.length == 0) {
          this.$router.replace("/myCar");
        } else {
          console.log(couponList)
          this.couponShow = true
        }
      }, () => {
        this.$router.replace("/myCar");
      })
    },
    // 优惠券确认弹窗事件
    seeCouponFun() {
      this.$router.replace("/coupon");

    },
    // 优惠券弹出取消事件
    cancelCouponFun() {
      this.$router.replace("/myCar");
    },
    bind() {
      this.form.plate = this.licenseNumber;
      if (!this.eidtSource) {
        // 新增来源
        bindCar(this.form).then((res) => {
          // 是否获得优惠券
          this.$msg(res.msg);
          let timer = setTimeout(() => {
            clearTimeout(timer)
            this.getBindCarCoupon()
          }, 200)
          // this.$router.replace("/myCar");

        }, () => {
          this.$msg(res.msg);
        });
      } else {
        // 编辑来源
        updateCar(this.form).then((res) => {
          if (res.code == 200) {
            this.$msg(res.msg);
          } else {
            this.$msg(res.msg);
          }
          this.$router.replace("/myCar");
        });
      }
    },

    bindStatusFun() {
      this.bindStatus =
        this.licenseNumber.length < 7 ||
          this.form.plateBrand == "" ||
          this.form.tagName == ""
          ? true
          : false;
      // ||this.form.etcName==''
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  /deep/ .van-button--info {
    font-size: 1.6rem;
    font-weight: 600;
    color: #ffffff;
    border-radius: 2.2rem;
  }

  /deep/ .van-cell {
    width: 92%;
    margin: 0 auto;
    border-radius: 4px;
    border: 1px solid #e4e6eb;
  }

  /deep/ .van-button--info {
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 3rem;
    color: #ffffff;
    border-radius: 0.8rem;
  }
}
.carpopup {
  text-align: left;
  /deep/ .van-index-bar {
    height: 100%;
    overflow: scroll;
  }
  /deep/ .van-index-bar__index {
    padding-top: 0.2rem;
    font-size: 1.4rem;
  }
  /deep/ .van-index-anchor {
    text-align: left;
  }
  /deep/ .van-index-anchor.van-index-anchor--sticky.van-hairline--bottom {
    left: 0 !important;
    z-index: 4;
    width: calc(100% - 4rem) !important;
    right: 4rem;
  }
  /deep/ .van-cell__title {
    position: relative;
    flex: none;
    display: inline-block;
    width: 3.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    font-size: 1.6rem;
    border-radius: 50%;
    text-align: center;
    margin-right: 1rem;
    background: linear-gradient(to bottom, #6cd9ff 0%, #6d49ff 100%);
    &:after {
      position: absolute;
      z-index: 1;
      top: 0.1rem;
      left: 0.1rem;

      width: 3rem;
      height: 3rem;
      background: #fff;
      border-radius: 50%;
      content: "";
    }
    span {
      position: relative;
      z-index: 2;

      background-image: -webkit-gradient(
        linear,
        0 0,
        0 bottom,
        from(#6cd9ff),
        to(#6d49ff)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    // background-image: url(http://www.jq22.com/tp/dd98045b-f687-4408-90b3-0f9b40fa8e96.jpg),
    //                   linear-gradient(to bottom right, #FF3CAC, #562B7C, #2B86C5);
  }
  /deep/ .van-cell__value {
    text-align: left;
    color: #303030;
    font-size: 1.4rem;
  }
}

// 关系列表
.relation-list {
  display: flex;
  width: 95%;
  margin: 0 auto;
  .item {
    flex: 1;
    font-size: 1.6rem;
    border-radius: 0.8rem;
    border: 0.1rem solid #cccccc;
    margin: 0 5px;
    color: #303030;
    line-height: 4rem;
    height: 4rem;
    &.active {
      border: 0.1rem solid #2b86ff;
      color: #2b86ff;
    }
  }
}
.container {
  padding: 3rem 1.6rem;

  .no-car {
    padding-top: 10vh;

    img {
      width: 94%;
      height: 25.6rem;
    }
  }

  .form {
    //  background-color: #ffffff;

    .title {
      margin: 2.5rem 0;
      font-size: 1.4rem;
      font-weight: 400;
      color: #666666;
      text-align: left;
    }

    .form-container {
      padding: 1rem 0 2rem;
      background-color: #ffffff;
    }

    .content {
      .tip {
        text-align: left;
        margin: 0 0 1rem 0;
      }
      .title {
        div:first-child {
          font-size: 1.8rem;
          font-weight: 600;
          color: #303030;
          margin-bottom: 0.6rem;
        }

        .tip {
          font-size: 1.4rem;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .number_box {
      height: 4.45rem;
      border-radius: 1rem;
      text-align: center;
      line-height: 4.45rem;
      font-size: 2.1rem;
      display: flex;

      .first-card {
        background: url("../../../assets/monthCard/first-card.png") no-repeat;
        background-size: 100% 100%;
        color: #ffffff;
      }

      .new-card {
        background-color: #69c923;
        color: #ffffff;
        position: relative;

        .tips-card {
          position: absolute;
          font-size: 1.2rem;
          top: -30px;
          color: #69c923;
          width: 4rem;
          left: -3px;
        }
      }
    }

    .number_box span {
      border: 1px #e3e3e3 solid;
      flex: 1;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-right: 5px;
    }

    .number_box .plate-ico {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #333333;
      margin-top: 15px;
      margin-right: 5px;
    }

    .pick-desc {
      font-size: 1.4rem;
      font-weight: 500;
      color: #333333;
      text-align: left;
      margin: 1.6rem 4%;
    }
  }

  // 优惠券弹窗
  .gongxi {
    padding-bottom: 1rem;
    span {
      color: #ff2e2e;
      font-size: 1.6rem;
    }
  }
}
</style>
