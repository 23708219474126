<template>
  <div class="container">
    <div class="success" v-if="payStatus==2">
      <img src="../../assets/monthCard/pay-success.png" />
      <div>支付完成</div>
      <div class="btn2" :style="isSuccess?'left:50%;margin-left: -8.3rem;':''" @click="toInvoice">开具发票</div>
      <div class="btn3">电子发票仅支持7日内开具，超出时效需到停车场开具纸质发票</div>
      <div v-if="isSuccess" class="btn-success" @click="toIndex"></div>
      <div v-else class="btn" @click="toIndex">返回首页</div>
    </div>
    <div class="error" v-if="payStatus!=2 && payStatus!=-1 ">
      <img src="../../assets/monthCard/pay-error.png" />
      <div>支付失败，请重试</div>
      <div class="btn position_center" @click="toIndex">重新支付</div>
    </div>
  </div>
</template>

<script>
import { OrderStatus } from '@/api/searchMonthCard'
import { apiCouponFlagBySuccessPay } from '@/api/coupon'
import { getAccessToken } from '@/util/store'

export default {
  name: "payResult",
  created() {
    this.initData();
  },
  data() {
    return {
      isSuccess: false,
      payStatus: -1,
    }
  },
  watch: {
    payStatus(val) {
      if (val == 2) {
        apiCouponFlagBySuccessPay(window.localStorage.getItem('openId')).then(res => {
          if (res.success && res.data.couponFlag) {
            this.isSuccess = true;
          }
        }).catch(err => {

        })
      }
    }
  },
  methods: {
    toInvoice() {
      this.$dialog.confirm({
        title: '开票提示',
        message: '请登录后前往，【我的】-【开具发票】页面开具电子发票',
        confirmButtonText: '立即前往'
      })
        .then(() => {
          // on confirm
          if (getAccessToken) {
            this.$router.push('/invoice');
          } else {
            this.$router.push('/login');
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    initData() {
      OrderStatus({ tradeNo: this.$route.query.orderId }).then(res => {
        this.payStatus = res.data.status;
      })
    },
    close() {
      this.$router.replace('/')
      // //关闭安卓
      // document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false);
      // //这个可以关闭ios系统的手机
      // WeixinJSBridge.call('closeWindow');
    },
    toIndex() {
      if (this.isSuccess) {
        this.$router.replace({
          path: "/couponList/getList",
          query: {
            isPay: 1
          }
        })
        return;
      }
      this.$router.replace('/');
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  padding-top: 5rem;
  min-height: calc(100vh - 5rem);
  background: #f6f7fb;
  .success {
    text-align: center;
    img {
      width: 9rem;
      height: 9rem;
    }
    div {
      font-size: 1.8rem;
      font-weight: 600;
      color: #303030;
      line-height: 50px;
    }
  }
  .error {
    text-align: center;
    img {
      width: 9rem;
      height: 9rem;
    }
    div {
      font-size: 1.8rem;
      font-weight: 600;
      color: #303030;
      line-height: 50px;
    }
  }
  .btn-success {
    position: absolute;
    height: 20.6rem;
    width: 36rem;
    background: url("../../assets/coupon/pay-success.png") no-repeat;
    left: 50%;
    transform: translateX(-50%);
    bottom: 12rem;
    background-position: center;
    background-size: contain;
  }
  .btn {
    position: absolute;
    height: 5rem;
    width: 16.7rem;
    line-height: 5rem;
    text-align: center;
    background-color: #ffffff;
    color: #3d90ff !important;
    border-radius: 10px;
    right:1rem;
    // margin-left: -8.3rem;
    bottom: 8rem;
    &.position_center{
      right: 50%;
      transform: translateX(50%);
    }
  }
  .btn2 {
    position: absolute;
    height: 5rem;
    width: 16.7rem;
    line-height: 5rem;
    text-align: center;
    background-color: #ffffff;
    color: #3d90ff !important;
    border-radius: 10px;
    left: 1rem;
    // margin-left: -8.3rem;
    bottom: 8rem;
  }
  .btn3 {
    position: absolute;
    line-height: 5rem;
    text-align: center;
    color: red !important;
    left: 0;
    right: 0;
    bottom: 2rem;
    font-size: 1.2rem !important;
  }
}
</style>
