<template>
  <div class="car-container">
    <div class="car_head">
      {{cardetailsData.plate}}
      <span class="update" @click="unbind">解绑</span>
      <!-- <img class="update" :src="require('../../../../assets/my/update.png')" alt=""> -->
    </div>
    <div class="car-news">
      <h2 class="title">车辆信息 <span class="eidt-car" @click="eidtCar">编辑</span></h2>
      <van-cell-group style="height:0;overflow:hidden;">
        <van-field label="车牌号" :value="cardetailsData.plate" readonly />
      </van-cell-group>

      <van-cell-group>
        <van-field label="车辆品牌" :value="cardetailsData.plateBrand" readonly />
      </van-cell-group>

      <van-cell-group>
        <van-field label="备注信息" :value="cardetailsData.remark" readonly />
      </van-cell-group>
      <van-cell-group>
        <van-field label="车辆关系" :value="cardetailsData.tagName" readonly />
      </van-cell-group>

    </div>

    <van-button type="info" style="width: 100%;position:fixed;bottom:0;left:0;" @click="monthCard">月卡信息</van-button>
  </div>
</template>

<script>
// import keyboard from "@/components/keyboard/keyboard";

import { getCarTypeList, updateCar, unbindCar } from "@/api/my/myCar";

export default {
  name: "carDetails",
  components: {
    // keyboard
  },
  data() {
    return {
      // licenseNumber: '贵',
      carTypePopup: false,
      relationPopup: false,
      fieldReadonly: true,
      carTypeColumns: [],
      // relationColumns:[{text:'我的车',id:1},{text:'朋友的车',id:2},{text:'亲戚的车',id:3}],
      cardetailsData: {},

    }
  },
  watch: {

  },
  mounted() {
    this.cardetailsData = this.$route.query.carDstails
    this.cardetailsData.tagName = this.$route.query.carDstails.tagNames[0]
    // this.licenseNumber = this.$route.query.carDstails.plate
  },
  created() {

  },
  methods: {
    // 月卡信息
    monthCard() {
      this.$router.replace({ path: './searchResult', query: { plate: this.cardetailsData.plate } })
    },
    // 解绑车辆
    unbind() {
      this.$dialog.confirm({
        title: '提示',
        message: `将解除绑定:${this.cardetailsData.plate},是否继续操作?`,
      })
        .then(() => {
          unbindCar(this.cardetailsData.id).then(res => {
            this.$msg(`${res.msg}`)
            this.$router.replace("/myCar")

          })
        })
        .catch(() => {
          // on cancel
        });
    },
    // 编辑车辆信息
    eidtCar() {
      this.$router.replace({ path: '/bindCar', query: { eidtCarNews: this.cardetailsData } })

    }

  },
  activated() {
    this.cardetailsData = this.$route.query.carDstails
    this.cardetailsData.tagName = this.$route.query.carDstails.tagNames[0]
    this.licenseNumber = this.$route.query.carDstails.plate

  }

}
</script>

<style lang="scss" scoped>
* {
  margin: 0 auto;
  padding: 0;
}
.car-container {
  width: 100%;
  padding: 1.4rem;
  box-sizing: border-box;
  .car_head {
    margin: 0 auto;
    background: url("../../../../assets/monthCard/jh-bg.png");
    background-size: 100% 8rem;
    height: 8rem;
    line-height: 4.4rem;
    font-size: 1.6rem;
    color: #303030;
    text-align: left;
    padding: 1.6rem;
    box-sizing: border-box;
    position: relative;
    .update,
    .cancel {
      position: absolute;
      top: 0;
      right: 0;
      width: 5.6rem;
      height: 2.8rem;
      font-size: 1.4rem;
      color: #2b86ff;
      background-color: #fff;
      line-height: 2.8rem;
      text-align: center;
      border-top-right-radius: 1.4rem;
      border-bottom-left-radius: 1.4rem;
    }
    .cancel {
      color: red;
    }
  }
  .car-news {
    background-color: #fff;
    margin-top: 1rem;
    padding: 1.6rem;
    border-radius: 1rem;

    .title {
      position: relative;
      color: #303030;
      font-size: 1.4rem;
      text-align: left;
      font-weight: 400;
      padding-left: 3.2rem;
      height: 3rem;
      line-height: 3rem;
      margin-bottom: 0.5rem;
      background: url("../../../../assets/my/icon1.png") no-repeat left
        center/3rem;
      .eidt-car {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #2b86ff;
        font-size: 1.4rem;
        padding: 0.5rem;
      }
    }

    .pick-desc {
      font-size: 1.4rem;
      font-weight: 500;
      color: #333333;
      text-align: left;
      margin: 1.6rem 0;
    }
    /deep/ .van-cell {
      font-size: 1.4rem;
      margin: 0 auto;
      border-radius: 4px;
      // border: 1px solid #E4E6EB;
      padding: 10px 0px;
    }
    /deep/ .van-cell-group::after {
      border-top-width: 0;
    }
  }
}
</style>