<template>
  <div class="container">
    <div class="content-title" style="margin-bottom: 1rem">
      <div class="list-content">
        <div class="car-info-item">
          <label>{{ data.parklotName }}</label>
          <div>{{ data.plate ? data.plate : "-" }}</div>
        </div>

        <div class="time-item">
          <div class="div-left">
            <div class="date">
              {{ data.enterTime != null ? initDate(data.enterTime) : "" }}
            </div>
            <div class="time">
              {{ data.enterTime != null ? initTime(data.enterTime) : "" }}
            </div>
          </div>
          <div class="div-right"></div>
          <div class="div-right"></div>
          <div class="div-right">
            <div class="date">
              <!-- {{ data.exitTime != null ? initDate(data.exitTime) : "" }} -->
            </div>
            <div class="time">
              <!-- {{ data.exitTime != null ? initTime(data.exitTime) : "" }} -->
            </div>
          </div>
        </div>
        <div class="indicator-div">
          <div class="solid-div"></div>
          <!-- <div class="left-div"></div> -->
          <div class="left-div"></div>
          <div class="centre-div"></div>
          <div class="right-div"></div>
        </div>
        <div class="indicator-title-div">
          <div class="left-title-div">进场停车</div>
          <div class="right-title-div">即将出场</div>
        </div>
      </div>
    </div>
    <div class="content" style="margin-bottom: 1rem">
      <div class="list-content">
        <div class="item">
          <label>入场时间</label>
          <div>{{ data.enterTime }}</div>
        </div>
        <div class="item">
          <label>出场时间</label>
          <!-- <div>{{ data.exitTime }}</div> -->
        </div>
        <div class="item">
          <label>停车时长</label>
          <div>{{ data.duration }}</div>
        </div>
        <div class="list-content" style="margin-bottom: 0">
          <div class="item-pri">
            <label>停车费用</label>
            <div>
              <div class="div-pri">
                {{ data.receiveAmount > 0 ? "￥" : "" }}{{ data.receiveAmount
                }}{{ "元" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="centre-icon">
        <!-- <div class="icon-div"></div> -->
        <img
          class="icon-div"
          src="../../../assets/monthCard/down-arrow-icon.png"
        />
      </div>
    </div>
    <!-- <div class="img-div">
      <img :src="data.enterImageUrl" />
    </div> -->

    <div class="img-div">
      <img style="max-height: 18rem; margin: 10rpx" :src="data.enterImageUrl" />
      <!-- <img
        style="max-height: 18rem; margin: 10rpx"
        src="http://leliven-park.oss-cn-chengdu.aliyuncs.com/upload/channel-pic/2022/09/000014/56bd674851e5486d8513cf69267f6f9f63180293b9f3414450393537.jpg"
      /> -->
    </div>
    <payPopup
      :show.sync="show"
      :totalAmount="data.receiveAmount"
      :isShowAl="data.scanAliMimiId == '' ? false : true"
      @closed="show = false"
      @onZfbPay="zfbPay"
      @onWxPay="wxPay"
    />
    <div
      class="pay-btn"
      v-show="data.receiveAmount > 0 ? true : false"
      @click="showSelectPayType"
    >
      立即支付
    </div>
  </div>
</template>

<script>
import { payDetail, tempPay } from "@/api/temporaryStop";
import payPopup from "@/components/payPopup";
import moment from "moment";
export default {
  name: "temporaryStop",
  components: {
    payPopup,
  },
  created() {
    this.todoId = this.$route.query.todoId;
    this.userId = this.$route.query.userId;

    this.initData();
  },
  data() {
    return {
      data: "", //返回的数据
      todoId: "", //代办id
      userId: "", //用户id
      payType: "", //支付类型
      btnLoading: false,
      show: false,
      optTime: 0,
      loopTime: 3000,
      optTimeMax: 180, //轮训时间停止时间
      id: "",
      stepperDefault: "0",
      interval: null,
      chooseData: {},
      chooseIndex: 0,
      //用于查询最新续费时间
      detailTimeData: {
        cardId: "",
        monthNum: "",
      },
      //续费参数
      formData: {
        cardId: "", //id
        renewalFeeMount: "", //缴费金额
        renewalFeeNum: "", //缴费月数
        reChargeType: "2", //是否第一次开卡
        returnUrl: "http://" + window.location.host + "/monthPayResult",
        payType: "",
        payId: "",
      },
      newTime: {
        endDate: "",
        startDate: "",
      },
    };
  },
  methods: {
    initTime(value) {
      return moment(value).format("HH:mm");
    },
    initDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    initData() {
      payDetail({ todoId: this.todoId }).then((res) => {
        this.data = res.data;
        console.log(res.data);
      });
    },
    showSelectPayType() {
      this.show = true;
    },
    stopClearInterval() {
      this.optTime = 0;
      clearInterval(this.interval);
      this.$router.replace("/payRes");
    },
    //定时 开始
    startSetInterval() {
      if (this.interval != null) {
        this.optTime = 0;
        clearInterval(this.interval);
      }

      this.interval = setInterval(this.tempPay, this.loopTime);
    },
    //查询支付接口
    tempPay() {
      this.optTime++;
      console.log(
        "optTime==" + this.optTime + ",this.optTimeMax==" + this.optTimeMax
      );
      if (this.optTime == this.optTimeMax) {
        clearInterval(this.interval);
      }
      let jsonData = { todoId: this.todoId };
      tempPay(jsonData).then((res) => {
        if (res.code == 200 && res.success == true && res.data == true) {
          console.log(res.data);
          this.stopClearInterval();
        }
      });
    },

    zfbPay() {
      this.show = false;
      this.startSetInterval();
      console.log("zfb-----");
      let param = "type=temp&orderId=" + this.todoId + "&userId=" + this.userId;
      let appId = this.data.scanAliMimiId;
      let page = "pages/jump-pay/index";

      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.jstwebview.startAliMini(appId, page, param);
      }
      if (isIOS) {
        let paramJson = {
          type: "temp",
          orderId: this.todoId,
          userId: this.userId,
        };
        let json = {
          appId: appId,
          page: page,
          param: paramJson,
        };
        console.log(JSON.stringify(json));

        window.webkit.messageHandlers.startAliMini.postMessage(
          JSON.stringify(json)
        );
      }
    },
    wxPay() {
      this.startSetInterval();
      this.show = false;
      let param = "type=temp&orderId=" + this.todoId + "&userId=" + this.userId;
      let appId = this.data.scanMimiId;
      let userName = this.data.scanMimiOriginalId;
      // let appId = "wxa8f99e190675abc0";
      // let userName = "gh_d86b1fb84d93";
      let path = "pages/jump-pay/index?" + param;
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.jstwebview.startWxMini(appId, userName, path);
      }
      if (isIOS) {
        let json = {
          userName: userName,
          path: path,
        };
        console.log("json", json);
        window.webkit.messageHandlers.startWxMini.postMessage(
          JSON.stringify(json)
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
>>> .van-button--round {
  border-radius: 1rem !important;
}
.container {
  padding: 1.6rem 1.6rem 1rem 1.6rem;
  min-height: calc(100vh - 3.2rem);
  background: #f6f7fb;

  .popu-content {
    display: flex;
    flex-direction: column;

    .close-div {
      padding: 1.6rem;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .left-title {
      }
      .right-title {
        background: #ffffff;
        border-radius: 2.4rem 2.4rem 0rem 0rem;
        opacity: 1;
      }
    }
    .pri-title-div {
      background: #ffffff;
      border-radius: 2.4rem 2.4rem 0rem 0rem;
      opacity: 1;
    }
    .pri-vlaue {
      margin-top: 1.8rem;
      font-size: 2rem;
      font-weight: 500;
      color: #3d90ff;
    }
    .pay-type-title-div {
      margin-top: 1.8rem;
      font-size: 1.4rem;
      font-weight: 500;
      color: #5b5b5b;
    }
    .pay-status-select {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: flex;
      text-align: center;
      margin-top: 0.8rem;
      height: 3.4rem;
      background: #3d90ff;
      color: #ffffff;
      border-radius: 0rem 0rem 0rem 0rem;
      opacity: 1;
      align-items: center;
      justify-content: center;
    }
    .pay-status-default {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: flex;
      text-align: center;
      margin-top: 0.8rem;
      height: 3.4rem;
      background: #ffffff;
      color: #3d90ff;
      border-radius: 0rem 0rem 0rem 0rem;
      opacity: 1;
      align-items: center;
      justify-content: center;
    }
  }

  .content-title {
    padding: 1.6rem;
    background-color: #ffffff;
    border-radius: 10px;
    .list-content {
      .indicator-title-div {
        margin-top: 0.8rem;
        margin-left: 12px;
        margin-right: 12px;
        height: 1.9rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-title-div {
          font-size: 1.4rem;
          font-weight: 500;
          color: #9a9a9a;
        }
        .right-title-div {
          font-size: 1.4rem;
          font-weight: 500;
          color: #9a9a9a;
        }
      }
      .indicator-div {
        position: relative;
        margin-left: 30px;
        margin-right: 30px;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .solid-div {
          width: 100vw;
          height: 2px;
          border-bottom: 2px dashed #878787;
        }

        .left-div {
          position: absolute;
          left: 0;
          background: #fff;
          width: 1rem;
          height: 1rem;
          border-radius: 1rem;
          opacity: 1;
          border: 1px solid #878787;
        }
        .right-div {
          position: absolute;
          right: 0;
          background: #fff;
          width: 1rem;
          height: 1rem;
          border-radius: 1rem;
          opacity: 1;
          border: 1px solid #878787;
        }
        .centre-div {
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          background: #fff;
          width: 1.9rem;
          height: 1.9rem;
          opacity: 1;
          border-radius: 1.2rem;
          border: 1px solid #878787;
        }
      }
      //车辆信息
      .car-info-item {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        align-items: center;
        margin-bottom: 0rem;

        label {
          font-size: 1.4rem;
          font-weight: 500;
          color: #2d2d2d;
        }

        div {
          flex: 1;
          text-align: right;
          font-size: 1.6rem;
          font-weight: 500;
          color: #3d90ff;
        }
      }
      .time-item {
        margin-top: 1.3rem;
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        align-items: center;
        margin-bottom: 0.4rem;
        .div-left {
          flex: 1;
          display: flex;
          justify-content: center;
          flex-wrap: nowrap;
          flex-direction: column-reverse;
          align-items: center;
          align-content: center;
          .time {
            text-align: center;
            font-size: 2.2rem;
            font-weight: 400;
            color: #2d2d2d;
          }

          .date {
            text-align: center;
            font-size: 1rem;
            font-weight: 500;
            color: #ababab;
          }
        }
        .div-right {
          flex: 1;
          display: flex;
          justify-content: center;
          flex-wrap: nowrap;
          flex-direction: column-reverse;
          align-items: center;
          align-content: center;

          .time {
            font-size: 2.2rem;
            font-weight: 400;
            color: #2d2d2d;
          }

          .date {
            font-size: 0.8rem;
            font-weight: 500;
            color: #ababab;
          }
        }
      }
      .item {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        align-items: center;
        margin-bottom: 0.8rem;

        label {
          font-size: 1.2rem;
          font-weight: 500;
          color: #9a9a9a;
        }

        div {
          flex: 1;
          text-align: right;
          font-size: 1.2rem;
          font-weight: 500;
          color: #9a9a9a;
        }
      }
    }
  }

  .content {
    position: relative;
    padding: 1.6rem;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 1.5rem;

    .list-content {
      .item {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        align-items: center;
        margin-bottom: 1rem;

        label {
          font-size: 1.4rem;
          font-weight: 500;
          color: #9a9a9a;
        }

        div {
          flex: 1;
          text-align: right;
          font-size: 1.4rem;
          font-weight: 500;
          color: #9a9a9a;
        }
      }
      .item-pri {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        align-items: center;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;

        label {
          font-size: 1.4rem;
          font-weight: 500;
          color: #9a9a9a;
        }

        div {
          flex: 1;
          text-align: right;
          font-size: 1.4rem;
          font-weight: 500;
          color: #9a9a9a;
        }
        //金额
        .div-pri {
          font-size: 2rem;
          font-weight: 500;
          color: #2376e5;
        }
      }

      .item:last-child {
        margin-bottom: 0;
      }
      .div-time {
        display: flex;
        font-size: 1.4rem;
        color: #333333;
        align-items: center;
        margin-bottom: 0rem;
      }
    }
    .centre-icon {
      display: flex;
      position: absolute;
      top: -0.6rem;
      left: 50%;
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 2.6rem;
      border: 1.2rem solid #f6f7fb;
      transform: translate(-50%, -50%);
      text-align: center;
      background: #ffffff;
      // background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC6klEQVRYR8WXX8iLURzHnynKn0x4UUItXO1NkbQVb1ny78aNXUzvDbWQC9J7oURICiH1hqVc2cVcKUVpRWFDRNsNsqKU/Mve/Clq8/nufZ617X337pxt2qlf5zzP8/v9vp9znvOc5xyfY1jK5fJkXDdg27F+rA+b64Z/of6M5bAb2F2fz/fXJLWvlRPCc/A5hO3CZrXyd59/p76KnQLk20QxTQEQ1rMD2FFspqFwo1uRGyewc4CUx8sxLoDb6+sEbGxTuDHsDjcGgdCrqitjABAfwEPiC7sk7qX5QGMHEPdr89YBIL6Yh0+xeV0W99J9orEaiPfejSoA4tO5KbpV/0ncS/ucxgAQP3SjFuA010M24slksuIei8VswuR7EoDDVQB6v4CLAjbVJlMikai4x+NxmzD5qvfLgPhYGQEAhqn22mbpAEBSwwDs8yE+hQutYtbfeocAI2j2CWATjdu2vZd/hwBKsVkAl2js7hHARQE8RDzcI4BHAniLeKBHAAUB/ER8Wo8AfglAfyzrL6BLk3BEAK9ItrxHI/BaAFr/15kAZLNZp1QqOeHw6Jxt/AxzOW2I2C71a8NkVO4J4Dyu+1u5F4tFJ5VKadV0gsFgBaIWQOKZTMZhdXOi0ajj9/tbpdTzCwJYTyNt4l0oFJx0Ol2FyOfzlbBQKFQVj0QiTiBg/FFFrJfiWohaaPXcUnx0KVYSRuEKlfEvrRGiDXHJXiZujweg3/EbbIbJq5CPB6G2Zc8VorVnafV37I7CMeojpgAehGqLd+6lP464dtt1OyJtRh5gK20g2vB9RsxaAH7XAbijoE3pE2x+G4lNQppvSr1oJuQa2rcw79hlktjE5ytOW+n541rnZgeTJTjdxFaYZDbweYHPNsTfNfpOdDTTF3EG24lp29ZO+UPQNewg4pr5Y4rJ4XSREmCD2GxDCu0xdbo6i7BORE1LSwAvkrkxibbmxxb31eg1adKq6KSj4X2JaX+ZRbhkAvsPiGEf4lYRS2MAAAAASUVORK5CYII=)
      //   no-repeat;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      .icon-div {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
  .img-div {
    border-radius: 1.2rem 1.2rem 1.2rem 1.2rem;
    opacity: 1;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0rem 0.1rem 0.3rem 0.1rem rgba(0, 0, 0, 0.1);
    border-radius: 1.2rem 1.2rem 1.2rem 1.2rem;
    opacity: 1;
  }

  .pay-btn {
    // position: fixed;
    margin-top: 50px;
    bottom: 2rem;
    height: 4rem;
    left: 1.6rem;
    right: 1.6rem;
    background: #3d90ff;
    border-radius: 12px;
    text-align: center;
    line-height: 4rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
  }
}
</style>
