import { get, post, DELETE } from '@/util/axios';


//获取用户绑定的车牌列表
export const getBindCarList = () => {
    return get(
        '/api/lecent-park/wechat/user-plate/list'
    )
}

//获取车辆品牌
export const getCarTypeList = () => {
    return get(
        '/api/lecent-park/carbrand/list'
    )
}

//建行绑定我的车辆
export const bindCar = (data) => {
    return post(
        '/api/lecent-park/wechat/user-plate/bind',
        data
    )
}
//建行修改我的车辆
export const updateCar = (data) => {
    return post(
        '/api/lecent-park/wechat/user-plate/update',
        data
    )
}
//建行解绑我的车辆
export const unbindCar = (id) => {
    return post(`/api/lecent-park/wechat/user-plate/unbind?bindId=${id}`)
}


