import { get, post } from '@/util/axios';


/**
 *
 * @param 根据代办查询详情
 * @returns {*}
 */
export const payDetail = (params) => {
    return get(
        '/api/open-api/park/open-h5/temp/v1/payDetail',
        params
    )
};

/**
 *
 * @param 查询支付结果
 * @returns {*}
 */
export const tempPay = (params) => {
    console.log(params);
    return get(
        '/api/open-api/park/open-h5/temp/v1/paySuccess',
        params
    )
};
