<template>
  <div class="container">
    <div class="content">
      <div class="pay-result-div"></div>
    </div>
    <div class="pay-result-title">缴费成功</div>
    <!-- <div class="pay-result-close-time">
      <div class="time">{{ number }}</div>
      <div class="lable">秒之后自动跳转</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "paySucces",
  created() {
    // this.startSetInterval();
  },
  data() {
    return {
      number: 5,
      interval: null,
    };
  },
  methods: {
    //定时 开始
    startSetInterval() {
      this.interval = setInterval(this.close, 1000);
    },
    stopClearInterval() {
      clearInterval(this.interval);

      console.log("-----关闭-");
    },
    close() {
      if (this.number == 0) {
        this.stopClearInterval();
      } else {
        this.number--;
      }
    },
  },
};
</script>
 
<style scoped lang="scss">
>>> .van-button--round {
  border-radius: 1rem !important;
}
.container {
  min-height: calc(100vh - 3.2rem);
  background: #f6f7fb;
  text-align: center;
  .content {
    display: flex;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;

    .pay-result-div {
      width: 22.36rem;
      height: 21.44rem;
      margin-top: 20rem;
      background: url("../../../assets/monthCard/pay-new-success.png") no-repeat;
      opacity: 1;
    }
  }
  .pay-result-title {
    margin-top: 5.4rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #6f6ade;
  }
  .pay-result-close-time {
    display: flex;
    margin-top: 12.4rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #8d8d8d;
    justify-content: center;
    align-items: center;

    .time {
      font-size: 3rem;
      font-weight: 500;
      color: #6f6ade;
    }

    .lable {
      margin-left: 0.5rem;
      margin-top: 0.5rem;
    }
  }
}
</style>
