<template>
  <div class="container">
    <div class="content-div">
      <div class="content">
        <div class="park-lot-box">
          <div class="line"></div>
          <div class="park-name">车场信息</div>
        </div>
        <div class="line-item"></div>
        <div class="item">
          <div class="left-div">
            <div class="info-div">
              <div class="title">{{ parklotName }}</div>
              <div class="value">{{ addr }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="park-lot-box">
          <div class="line"></div>
          <div class="park-name">车主信息</div>
        </div>
        <div class="line-item"></div>
        <div class="item">
          <div class="title-value-div">
            <div class="title-div">
              <div class="mark">*</div>
              <div class="title">车牌号</div>
            </div>
            <div class="test-key">
              <plateKeyBoard ref="child" v-model="licenseNumber" />
            </div>
          </div>
        </div>

        <div class="item">
          <div class="title-value-div">
            <div class="title-div">
              <div class="mark">
                <div>*</div>
              </div>
              <div class="title">申请人</div>
            </div>
            <input
              class="input-value"
              @touchstart="complatePlate"
              style="border-style: none; text-align: right"
              v-model="userNameValue"
              placeholder="请输入月卡所有人"
              type="text"
              maxlength="20"
            />
          </div>
        </div>
        <div class="item">
          <div class="title-value-div">
            <div class="title-div">
              <div class="mark">*</div>
              <div class="title">电话</div>
            </div>
            <input
              @touchstart="complatePlate"
              style="border-style: none; text-align: right"
              class="input-value"
              v-model="phoneValue"
              placeholder="请输入电话"
              type="text"
              maxlength="12"
            />
          </div>
        </div>

        <div class="item">
          <div class="title-value-div">
            <div class="title-div">
              <div class="title">备注</div>
            </div>
            <input
              @touchstart="complatePlate"
              style="border-style: none; text-align: right"
              class="input-value"
              v-model="remarkValue"
              placeholder="请输入备注"
              type="text"
              maxlength="100"
            />
          </div>
        </div>
      </div>

      <div class="content">
        <div class="park-lot-box">
          <div class="line"></div>
          <div class="park-name">车场信息</div>
        </div>
        <div class="line-item"></div>
        <div class="item">
          <div class="title-value-div">
            <div class="title-div">
              <div class="title">续费金额</div>
            </div>
            <div class="value-pri">￥{{ renewalFeeMount }}</div>
          </div>
        </div>
        <div class="item">
          <div class="title-value-div">
            <div class="title-div">
              <div class="title">月卡种类</div>
            </div>
            <div class="value-div">{{ categoryName }}</div>
          </div>
        </div>
        <div class="item">
          <div class="title-value-div">
            <div class="title-div">
              <div class="title">开通时间</div>
            </div>
            <div class="value-div">{{ cardStartTime }}</div>
          </div>
        </div>
        <div class="item">
          <div class="title-value-div">
            <div class="title-div">
              <div class="title">失效时间</div>
            </div>
            <div class="value-div">{{ newEndDate }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="back-div">
      <div class="btn" @click="back">返回上一步</div>
      <div class="pay-btn" @click="onCreateMonthCard">确认无误，去支付</div>
    </div>

    <payPopup
      :show="show"
      :totalAmount="renewalFeeMount"
      :isShowAl="orderInfo.scanAliMimiId == '' ? false : true"
      @closed="show = false"
      @onZfbPay="zfbPay"
      @onWxPay="wxPay"
    />
  </div>
</template>

<script>
import {
  onlineOpenCategories,
  getRenewTimeDuration,
  onlineNewMonthlyCardPay,
  cardPay,
} from "@/api/monthCard";
import plateKeyBoard from "@/components/plateKeyboardNew";
import payPopup from "@/components/payPopup";
import moment from "moment";
import { Notify } from "vant";
export default {
  components: {
    plateKeyBoard,
    payPopup,
  },
  created() {
    let parklotId = this.$route.query.parklotId; //车场id
    this.parklotId = parklotId;
    this.parklotName = this.$route.query.parklotName; //车场名字
    this.categoryName = this.$route.query.categoryName; //套餐名字
    this.categoryId = this.$route.query.categoryId; //套餐id
    let addr = this.$route.query.addr; //车场地址
    if (
      addr == undefined ||
      addr == "" ||
      addr == "undefined" ||
      addr == "null"
    ) {
      this.addr = "";
    } else {
      this.addr = addr;
    }
    this.renewalFeeNum = this.$route.query.renewalFeeNum; //月数
    this.cardStartTime = this.$route.query.cardStartTime; //开始时间
    this.newEndDate = this.$route.query.newEndDate; //结束时间
    this.renewalFeeMount = this.$route.query.renewalFeeMount; //金额
    this.userId = this.$route.query.userId; //用户id
    // console.log("车场id====" + this.parklotId);
    // console.log("车场名字====" + this.parklotName);
    // console.log("套餐名字====" + this.categoryName);
    // console.log("套餐id====" + this.categoryId);
    // console.log("月数====" + this.renewalFeeNum);
    // console.log("开始时间====" + this.cardStartTime);
    // console.log("结束时间====" + this.newEndDate);
    // console.log("金额====" + this.renewalFeeMount);
    // console.log("用户id====" + this.userId);
    this.initData();
  },
  data() {
    return {
      parklotId: "",
      parklotName: "",
      licenseNumber: "",
      cardStartTime: "",
      renewalFeeMount: "",
      userId: "",
      addr: "",
      // setMealItem: "", //选中的套餐
      // setMealItems: [],
      categoryId: "",
      categoryName: "",
      userNameValue: "",
      renewalFeeNum: "0", //月数
      phoneValue: "",
      remarkValue: "",
      optTime: 0,
      loopTime: 3000, //轮循周期
      optTimeMax: 180, //轮训时间停止时间

      show: false, //是否展示支付弹框
      cardId: "", //车辆id
      orderId: "", //订单号
      btnLoading: false,
      renewalFeeMount: "", //金额
      showPicker: false, //是否显示下拉选择器
      stepperChangeNum: false,
      newEndDate: "", //结束时间
      id: "",
      data: {},
      chooseData: {},
      chooseIndex: -1,
      interval: null,
      newTime: {
        endDate: "",
        startDate: "",
      },
      //开卡后的支付信息
      orderInfo: "",
    };
  },
  methods: {
    complatePlate() {
      this.$refs.child.complateChild();
    },
    back() {
      this.$router.push(
        "/ct/monthCardCreate?parklotId=" + //车场id
          this.parklotId +
          "&categoryIdNext=" + //套餐id
          this.categoryId +
          "&renewalFeeNumNext=" + //月数
          this.renewalFeeNum +
          "&userId=" + //用户id
          this.userId
      );

      // this.$router.go(-1);
    },
    //验证电话号码格式
    validatePhone(val) {
      var isPhone = /^1[0-9]{10}$/g;
      // var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/; //手机号码
      var isMob = /^0?1[3|4|5|8][0-9]\d{8}$/; // 座机格式  区号之后用'-'隔开
      if (isMob.test(val) || isPhone.test(val)) {
        return true;
      } else {
        return false;
      }
    },

    stopClearInterval() {
      this.optTime = 0;
      clearInterval(this.interval);
      this.$router.replace("/payRes");
    },
    //定时 开始
    startSetInterval() {
      if (this.interval != null) {
        this.optTime = 0;
        clearInterval(this.interval);
      }
      this.interval = setInterval(this.cardPay, this.loopTime);
    },
    //查询支付接口
    cardPay() {
      this.optTime++;
      console.log(
        "optTime==" + this.optTime + ",this.optTimeMax==" + this.optTimeMax
      );
      if (this.optTime == this.optTimeMax) {
        clearInterval(this.interval);
      }
      let jsonData = { orderId: this.orderId };
      cardPay(jsonData).then((res) => {
        if (res.code == 200 && res.success == true && res.data == true) {
          this.stopClearInterval();
        }
      });
    },

    //开通月卡点击
    onCreateMonthCard() {
      if (this.licenseNumber == "") {
        Notify({ type: "warning", message: "请输入车牌" });
        return;
      }
      if (this.licenseNumber.length < 7) {
        Notify({ type: "warning", message: "请输入正确的车牌" });
        return;
      }
      if (this.userNameValue == "") {
        Notify({ type: "warning", message: "请输入申请人姓名" });
        return;
      }
      if (this.phoneValue == "") {
        Notify({ type: "warning", message: "请输入电话" });
        return;
      }

      if (!this.validatePhone(this.phoneValue)) {
        Notify({ type: "warning", message: "请输入正确的电话" });
        return;
      }
      if (this.renewalFeeNum == 0 || this.renewalFeeNum == "0") {
        Notify({ type: "warning", message: "月数不能为0" });
        return;
      }

      let jsonData = {
        ownerName: this.userNameValue,
        phone: this.phoneValue,
        plate: this.licenseNumber,
        remark: this.remarkValue,

        cardStartTime: this.data.startTime,
        parklotId: this.parklotId,
        categoryId: this.categoryId,
        renewalFeeNum: this.renewalFeeNum,
        renewalFeeMount: this.renewalFeeMount,
        userId: this.userId,
      };
      console.log("jsonData", jsonData);
      onlineNewMonthlyCardPay(jsonData).then((res) => {
        console.log(res);
        this.orderInfo = res.data;
        this.orderId = res.data.orderId;
        this.show = true;
      });
    },
    //获取套餐
    initData() {},
    onConfirm(value, index) {
      this.showPicker = false;
      this.setMealItem = this.data.cardCategories[index];
      this.stepperChangeNum = false;
      this.newEndDate = "";
      this.chooseIndex = -1;
      this.renewalFeeNum = 0;
      console.log("index", index);
      this.stepperChange(1);
    },
    onChange(picker, value, index) {},
    onCancel() {
      this.showPicker = false;
      // Toast("取消");
    },
    //支付宝支付
    zfbPay() {
      this.startSetInterval();
      this.show = false;
      let param =
        "type=card&orderId=" + this.orderId + "&userId=" + this.userId;
      let appId = this.orderInfo.scanAliMimiId;
      let page = "pages/jump-pay/index";

      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.jstwebview.startAliMini(appId, page, param);
      }
      if (isIOS) {
        let paramJson = {
          type: "card",
          orderId: this.orderId,
          userId: this.userId,
        };
        let json = {
          appId: appId,
          page: page,
          param: paramJson,
        };
        console.log(JSON.stringify(json));

        window.webkit.messageHandlers.startAliMini.postMessage(
          JSON.stringify(json)
        );
      }
    },
    //微信支付
    wxPay() {
      this.startSetInterval();
      this.show = false;
      let param = "type=card&orderId=" + this.orderId + "&userId=" + this.userId;
      let appId = this.orderInfo.scanMimiId;
      let userName = this.orderInfo.scanMimiOriginalId;

      // let appId = "wxa8f99e190675abc0";
      // let userName = "gh_d86b1fb84d93";

      let path = "pages/jump-pay/index?" + param;
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.jstwebview.startWxMini(appId, userName, path);
      }
      if (isIOS) {
        let json = {
          userName: userName,
          path: path,
        };
        console.log("json", json);
        window.webkit.messageHandlers.startWxMini.postMessage(
          JSON.stringify(json)
        );
      }
    },
    //初始化日期
    initDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    //步进器变化监听
    stepperChange(event) {
      for (let i = 0; i < this.setMealItem.monthPrice.length; i++) {
        if (this.setMealItem.monthPrice[i].month == event) {
          this.chooseIndex = i;
        }
      }
      this.initEndTime(event);
    },
    //缴费结束时间
    initEndTime(event) {
      this.renewalFeeNum = event;

      if (event != 0) {
        let jsonData = {
          categoryId: this.setMealItem.id,
          monthNum: event,
          nextStartTime: this.data.startTime,
        };
        console.log("jsonData", jsonData);
        getRenewTimeDuration(jsonData).then((res) => {
          this.newEndDate = res.data.endDate;
          this.renewalFeeMount = res.data.totalAmount;
          // this.changRadio();
        });
      } else {
        this.stepperChangeNum = false;
        this.newEndDate = "";
        this.chooseIndex = -1;
        this.renewalFeeMount = 0;
      }
    },

    //续费月份点击
    chooseed(i, item) {
      this.chooseIndex = i;
      this.initEndTime(item.month);
    },
    //下单
    renewalFee() {
      if (this.renewalFeeNum == 0) {
        Notify({ type: "warning", message: "缴费月数不能为0" });
        return;
      }

      let jsonData = {
        cardId: this.cardId,
        renewalFeeNum: this.renewalFeeNum,
        renewalFeeMount: this.totalPri,
        reChargeType: "1",
        userId: this.userId,
        returnUrl: "www.baidu.com",
      };

      renewalFee(jsonData).then((res) => {
        console.log(res);
        this.orderId = res.data;
        this.show = true;
        // this.changRadio();
      });
    },
  },
};
</script>

<style scoped lang="scss">
>>> .van-button--round {
  border-radius: 1rem !important;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 1.4rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #d0d0d0;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 1.4rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #d0d0d0;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 1.4rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #d0d0d0;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 1.4rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #d0d0d0;
}
.container {
  padding: 1.6rem 0rem 0rem 0rem;
  min-height: calc(100vh - 3.2rem);
  background: #f6f7fb;
  .content-div {
    height: calc(100vh - 12rem);
    overflow: auto;
    margin-bottom: 2rem;
    .content {
      margin-bottom: 1rem;
      padding: 1.6rem;
      background-color: #ffffff;
      border-radius: 10px;
      .input-box {
        display: flex;
        flex-direction: column;
        .input-item {
          flex-direction: row;
          align-items: center;
          text-align: center;
          display: flex;
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
          .input-name {
            display: flex;
            flex-direction: row;
            text-align: center;
            font-size: 1.6rem;
            font-weight: 500;
            color: #303030;
            margin-bottom: 0.5rem;
            .mark {
              color: #ff0000;
              text-align: center;
            }
          }
          .input-value {
            line-height: 40px;
            margin-left: 10px;
            font-size: 1.6rem;
            font-weight: 600;
            color: #303030;
            margin-bottom: 0.5rem;
          }
        }
      }

      .test-key {
        padding-right: 3px;
        margin-bottom: 5px;
        width: 60%;
        text-align: center;
        font-weight: 600;
      }

      .park-lot-box {
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        align-items: center;
        .line {
          width: 0.1rem;
          height: 1.2rem;
          background: #2376e5;
          opacity: 1;
          border-radius: 2rem;
          border: 0.1rem solid #2376e5;
        }
        .park-name {
          margin-left: 5px;
          font-size: 1.6rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #242424;
        }
      }

      .line-item {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
        height: 0.05rem;
        background: #d8d8d8;
        opacity: 1;
      }

      .time-select {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }

      .choose-price {
        margin-top: 16px;
        .choose-box {
          .choose-item {
            display: flex;
            flex-direction: column-reverse;
            width: 10rem;
            height: 10rem;
            border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
            opacity: 1;
            border: 0.1rem solid #f0efef;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            div:first-child {
              font-size: 1.6rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #242424;
            }

            div:last-child {
              font-size: 2.2rem;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #242424;
            }
          }

          .chooseed {
            background: #fff7f5;
            border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
            opacity: 1;
            border: 0.1rem solid #ed6855;

            div:first-child {
              font-size: 1.6rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #242424;
            }

            div:last-child {
              font-size: 2.2rem;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #242424;
            }
          }
        }
      }
      .item {
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        .left-div {
          display: flex;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;

          .icon {
            margin-right: 1.3rem;
          }
          .info-div {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            text-align: left;
            .line {
              background: #919191;
              height: 1rem;
            }

            .title {
              font-size: 1.6rem;
              font-weight: 500;
              color: #303030;
              background: #ffffff;
              opacity: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .value {
              padding-top: 0.5rem;
              font-size: 1.4rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #9b9a9a;
              flex-wrap: nowrap;
              flex-direction: column;
              text-align: left;
            }
            .input {
              font-size: 1.4rem;
              font-weight: 500;
              color: #303030;
            }
          }
        }

        .title-value-div {
          width: 100vw;
          display: flex;
          line-height: 3rem;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          .title-div {
            font-size: 1.4rem;
            font-family: PingFang SC-Regular, PingFang SC;
            display: flex;
            line-height: 3rem;
            align-content: center;
            align-items: center;
            .mark {
              line-height: 1rem;
              font-size: 1.4rem;
              color: #ff1515;
              justify-content: center;
              flex-direction: row-reverse;
            }
            .title {
              text-align: center;
              height: 3rem;
              color: #5f5e5e;
            }
          }
          .input-value {
            font-size: 1.4rem;
            font-weight: 500;
            color: #303030;
          }
          .value-div {
            font-size: 1.4rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #242424;
          }
          .value-pri {
            font-size: 1.8rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 600;
            color: #2376e5;
          }
          .hasData {
            font-size: 1.4rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
          }
          .nullData {
            font-size: 1.4rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #d0d0d0;
          }
        }
      }
    }
  }

  .back-div {
    position: fixed;
    bottom: 2rem;
    left: 1.6rem;
    right: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .btn {
      font-size: 1.6rem;
      color: #2376e5;
      margin-bottom: 1rem;
    }
    .pay-btn {
      bottom: 2rem;
      height: 4rem;
      left: 1.6rem;
      right: 1.6rem;
      background: #3d90ff;
      border-radius: 1.6rem;
      text-align: center;
      line-height: 4rem;
      color: #ffffff;
      font-size: 1.6rem;
    }
  }
}
</style>
