<template>
  <div class="container">
    <div class="content-div">
      <div class="content">
        <div class="park-lot-box">
          <div class="line"></div>
          <div class="park-name">车场信息</div>
        </div>
        <div class="line-item"></div>
        <div class="item">
          <div class="left-div">
            <div class="info-div">
              <div class="title">{{ data.parklotName }}</div>
              <div class="value">{{ data.addr }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="park-lot-box">
          <div class="line"></div>
          <div class="park-name">选择套餐</div>
        </div>
        <div class="choose-price">
          <van-row>
            <template v-for="(item, i) in setMealItems">
              <van-col span="8">
                <div class="choose-box" @click="chooseedNew(i, item)">
                  <div
                    class="choose-item"
                    :class="chooseIndex == i ? 'chooseed' : ''"
                  >
                    <div>{{ item.name }}</div>
                    <div>
                      <div class="symbol-div">￥</div>
                      <div class="pri-div">{{ item.unitPrice }}</div>
                    </div>
                  </div>
                </div>
              </van-col>
            </template>
          </van-row>
        </div>
      </div>

      <div class="content">
        <div class="park-lot-box">
          <div class="line"></div>
          <div class="park-name">购买月数</div>
        </div>
        <div class="line-white" style="background: #ffffff"></div>
        <div class="list-content" style="margin-bottom: 0">
          <div class="item">
            <label>选择购买月数</label>
            <div>
              <van-stepper
                v-model="stepperDefault"
                disable-input
                min="0"
                @change="stepperChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pay-btn" v-show="isShowPay" @click="createMonthCard">
      点击办理
    </div>
  </div>
</template>

<script>
import { onlineOpenCategories, getRenewTimeDuration } from "@/api/monthCard";
import moment from "moment";
import { Notify } from "vant";
export default {
  created() {
    this.parklotId = this.$route.query.parklotId;
    if (this.$route.query.userId != "" && this.$route.query.userId != null) {
      this.userId = this.$route.query.userId;
    }
    if (
      this.$route.query.categoryIdNext != "" &&
      this.$route.query.categoryIdNext != null
    ) {
      this.categoryIdNext = this.$route.query.categoryIdNext;
    }
    if (
      this.$route.query.renewalFeeNumNext != "" &&
      this.$route.query.renewalFeeNumNext != null
    ) {
      this.renewalFeeNumNext = this.$route.query.renewalFeeNumNext;
    }
    this.initData();
  },
  data() {
    return {
      categoryIdNext: "", //下一个页面返回来的套餐id
      renewalFeeNumNext: "", //下一个页面返回来的月数
      licenseNumber: "",
      setMealItem: "", //选中的套餐
      setMealItems: [],
      userNameValue: "",
      phoneValue: "",
      remarkValue: "",
      userId: "",
      optTime: 0,
      loopTime: 3000, //轮循周期
      optTimeMax: 180, //轮训时间停止时间
      parklotId: "",
      show: false, //是否展示支付弹框
      cardId: "", //车辆id
      orderId: "", //订单号
      btnLoading: false,
      totalAmount: "", //金额
      showPicker: false, //是否显示下拉选择器
      stepperChangeNum: false,
      newEndDate: "", //结束时间
      id: "",
      stepperDefault: "0", //月数
      isShowPay: false, //是否展示支付
      data: {},
      chooseData: {},
      chooseIndex: -1,
      interval: null,
      newTime: {
        endDate: "",
        startDate: "",
      },
      //开卡后的支付信息
      orderInfo: "",
    };
  },
  methods: {
    createMonthCard() {
      if (this.stepperDefault == 0) {
        Notify({ type: "warning", message: "购买月数不能为0" });
        return;
      }

      let addr = this.data.addr;
      if (addr == undefined || addr == "") {
        addr = "";
      }
      this.$router.push(
        "/ct/monthCardCreateDetail?parklotName=" + //车场名字
          this.data.parklotName +
          "&parklotId=" + //车场id
          this.parklotId +
          "&addr=" + //车场地址
          addr +
          "&categoryName=" + //套餐名字
          this.setMealItem.name +
          "&categoryId=" + //套餐id
          this.setMealItem.id +
          "&renewalFeeNum=" + //月数
          this.stepperDefault +
          "&cardStartTime=" + //开始时间
          this.data.startTime +
          "&newEndDate=" + //结束时间
          this.newEndDate +
          "&renewalFeeMount=" + //金额
          this.totalAmount +
          "&userId=" + //用户id
          this.userId
      );
    },
    //验证电话号码格式
    validatePhone(val) {
      var isPhone = /^1[0-9]{10}$/g;
      // var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/; //手机号码
      var isMob = /^0?1[3|4|5|8][0-9]\d{8}$/; // 座机格式  区号之后用'-'隔开
      if (isMob.test(val) || isPhone.test(val)) {
        return true;
      } else {
        return false;
      }
    },

    //获取套餐
    initData() {
      onlineOpenCategories({ parklotId: this.parklotId }).then((res) => {
        this.data = res.data;
        if (res.data.cardCategories.length > 0) {
          this.isShowPay = true;

          if (this.categoryIdNext != "" && this.categoryIdNext != null) {
            for (let i = 0; i < res.data.cardCategories.length; i++) {
              this.setMealItems.push(res.data.cardCategories[i]);
              if (this.categoryIdNext == res.data.cardCategories[i].id) {
                this.setMealItem = res.data.cardCategories[i];
                if (
                  this.renewalFeeNumNext != "" &&
                  this.renewalFeeNumNext != null
                ) {
                  this.chooseIndex = i;
                  this.stepperChange(this.renewalFeeNumNext);
                } else {
                  this.chooseIndex = 0;
                  this.stepperChange(1);
                }
              }
            }
          } else {
            this.setMealItem = res.data.cardCategories[0];
            for (let i = 0; i < res.data.cardCategories.length; i++) {
              this.setMealItems.push(res.data.cardCategories[i]);
            }

            this.chooseIndex = 0;
            this.stepperChange(1);
          }
        }
      });
    },

    //初始化日期
    initDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    //步进器变化监听
    stepperChange(event) {
      this.initEndTime(event);
    },
    //缴费结束时间
    initEndTime(event) {
      this.stepperDefault = event;
      if (event != 0) {
        let jsonData = {
          categoryId: this.setMealItem.id,
          monthNum: event,
          nextStartTime: this.data.startTime,
        };
        console.log("jsonData", jsonData);
        getRenewTimeDuration(jsonData).then((res) => {
          this.newEndDate = res.data.endDate;
          this.totalAmount = res.data.totalAmount;
          // this.changRadio();
        });
      } else {
        this.stepperChangeNum = false;
        this.newEndDate = "";
        this.totalAmount = 0;
      }
    },
    chooseedNew(i, item) {
      this.setMealItem = this.data.cardCategories[i];
      this.stepperChangeNum = false;
      this.newEndDate = "";
      this.chooseIndex = i;
      this.stepperChange(this.stepperDefault);
    },

    //续费月份点击
    chooseed(i, item) {
      this.chooseIndex = i;
      this.initEndTime(item.month);
    },
    //下单
    renewalFee() {
      if (this.stepperDefault == 0) {
        Notify({ type: "warning", message: "缴费月数不能为0" });
        return;
      }

      let jsonData = {
        cardId: this.cardId,
        renewalFeeNum: this.stepperDefault,
        renewalFeeMount: this.totalPri,
        reChargeType: "1",
        userId: this.userId,
        returnUrl: "www.baidu.com",
      };

      renewalFee(jsonData).then((res) => {
        console.log(res);
        this.orderId = res.data;
        this.show = true;
        // this.changRadio();
      });
    },
  },
};
</script>

<style scoped lang="scss">
>>> .van-button--round {
  border-radius: 1rem !important;
}
.container {
  padding: 1.6rem 0rem 0rem 0rem;
  background: #f6f7fb;
  .content-div {
    height: calc(100vh - 10rem);
    overflow: auto;
    margin-bottom: 2rem;
    .content {
      margin-bottom: 1rem;
      padding: 1.6rem;
      background-color: #ffffff;
      border-radius: 10px;

      .park-lot-box {
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        align-items: center;
        .line {
          width: 0.1rem;
          height: 1.2rem;
          background: #2376e5;
          opacity: 1;
          border-radius: 1rem;
          border: 0.1rem solid #2376e5;
        }
        .park-name {
          margin-left: 5px;
          font-size: 1.6rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #242424;
        }
      }

      .line-item {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
        height: 0.05rem;
        background: #d8d8d8;
        opacity: 1;
      }
      .line-white {
        margin-top: 1rem;
        margin-bottom: 1rem;
        height: 0.05rem;
        background: #ffffff;
        opacity: 1;
      }

      .input-box {
        display: flex;
        flex-direction: column;

        .input-item {
          flex-direction: row;
          align-items: center;
          text-align: center;
          display: flex;
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
          .input-name {
            display: flex;
            flex-direction: row;
            text-align: center;
            font-size: 1.6rem;
            font-weight: 500;
            color: #303030;
            margin-bottom: 0.5rem;
            .mark {
              margin-top: 0.2rem;
              color: #ff0000;
              text-align: center;
            }
          }
          .input-value {
            line-height: 40px;
            margin-left: 10px;
            font-size: 1.6rem;
            font-weight: 500;
            color: #303030;
            margin-bottom: 0.5rem;
          }
        }
        .line {
          height: 0.05rem;
          background: #999999;
        }
      }

      .time-select {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }
      .list-content {
        margin-bottom: 1.6rem;

        .item {
          display: flex;
          font-size: 1.4rem;
          color: #333333;
          align-items: center;
          margin-bottom: 1rem;

          label {
            font-size: 1.4rem;
            color: #9a9a9a;
          }

          div {
            flex: 1;
            text-align: right;
            font-size: 1.2rem;
            font-weight: 500;
            color: #9a9a9a;
          }
          //金额
          .div-pri {
            font-size: 2.2rem;
            font-weight: 500;
            color: #2376e5;
          }
        }

        .item:last-child {
          margin-bottom: 0;
        }
      }
      .choose-price {
        margin-top: 16px;
        .choose-box {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px;
          .choose-item {
            box-sizing: border-box;
            flex-direction: column;
            padding: 1rem;
            width: 10rem;
            height: 10rem;
            border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
            opacity: 1;
            border: 0.1rem solid #f0efef;
            margin-bottom: 0.5rem;
            div:first-child {
              width: 8.6rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 1.4rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 600;
              color: #242424;
            }

            div:last-child {
              width: 8rem;
              margin-top: 1rem;
              color: #242424;
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
              justify-content: center;
              flex-direction: row;
              .symbol-div {
                margin-top: 1.2rem;
                font-size: 1rem;
                height: 1.2rem;
                font-weight: 600;
                width: auto;
              }
              .pri-div {
                width: auto;
                height: 3rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 2rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
              }
            }
          }

          .chooseed {
            box-sizing: border-box;
            width: 10rem;
            height: 10rem;
            padding: 1rem;
            background: #fff7f5;
            border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
            opacity: 1;
            border: 0.2rem solid #ed6855;
            margin-bottom: 0.5rem;
          }
        }
      }
      .item {
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        .left-div {
          display: flex;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;

          .icon {
            margin-right: 1.3rem;
          }
          .info-div {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            text-align: left;
            .line {
              background: #919191;
              height: 1rem;
            }

            .title {
              font-size: 1.6rem;
              font-weight: 500;
              color: #303030;
              background: #ffffff;
              opacity: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .value {
              padding-top: 0.5rem;
              font-size: 1.4rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #9b9a9a;
              flex-wrap: nowrap;
              flex-direction: column;
              text-align: left;
            }
            .input {
              font-size: 1.4rem;
              font-weight: 500;
              color: #303030;
            }
          }
        }
        .next-icon {
        }
      }
    }
  }

  .pay-btn {
    position: fixed;
    bottom: 2rem;
    height: 4rem;
    left: 1.6rem;
    right: 1.6rem;
    background: #3d90ff;
    border-radius: 12px;
    text-align: center;
    line-height: 4rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
  }
}
</style>
