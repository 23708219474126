<template>
  <div>
    <div class="key_input">
      <!-- <template v-for="(item, k) in i">
        <div class="num" :key="k" @touchstart="selected(k)">
          <div :class="[k == 6 && isCN ? 'cn' : '']">{{ inputArray[k] }}</div>
          <span :class="chooseIndex == k ? 'blink' : ''"></span>
        </div>
        <div class="spot" v-if="k === 1"></div>
      </template> -->
      <div
        :class="plate == '' ? 'nullData' : 'hasData'"
        @touchstart="selectedNew()"
      >
        {{ plate == "" ? key : plate }}
      </div>
    </div>
    <div class="board" v-if="chooseIndex != -1">
      <div class="b_header" @touchstart="complate"><span>完成</span></div>
      <div class="area_board" v-if="chooseIndex == 0">
        <div class="plate_num" @touchstart="chooseValue('京')">京</div>
        <div class="plate_num" @touchstart="chooseValue('津')">津</div>
        <div class="plate_num" @touchstart="chooseValue('冀')">冀</div>
        <div class="plate_num" @touchstart="chooseValue('晋')">晋</div>
        <div class="plate_num" @touchstart="chooseValue('蒙')">蒙</div>
        <div class="plate_num" @touchstart="chooseValue('沪')">沪</div>
        <div class="plate_num" @touchstart="chooseValue('苏')">苏</div>
        <div class="plate_num" @touchstart="chooseValue('皖')">皖</div>
        <div class="plate_num" @touchstart="chooseValue('浙')">浙</div>
        <div class="plate_num" @touchstart="chooseValue('闽')">闽</div>
        <div class="plate_num" @touchstart="chooseValue('鲁')">鲁</div>
        <div class="plate_num" @touchstart="chooseValue('粤')">粤</div>
        <div class="plate_num" @touchstart="chooseValue('桂')">桂</div>
        <div class="plate_num" @touchstart="chooseValue('琼')">琼</div>
        <div class="plate_num" @touchstart="chooseValue('赣')">赣</div>
        <div class="plate_num" @touchstart="chooseValue('豫')">豫</div>
        <div class="plate_num" @touchstart="chooseValue('鄂')">鄂</div>
        <div class="plate_num" @touchstart="chooseValue('湘')">湘</div>
        <div class="plate_num" @touchstart="chooseValue('陕')">陕</div>
        <div class="plate_num" @touchstart="chooseValue('甘')">甘</div>
        <div class="plate_num" @touchstart="chooseValue('青')">青</div>
        <div class="plate_num" @touchstart="chooseValue('宁')">宁</div>
        <div class="plate_num" @touchstart="chooseValue('新')">新</div>
        <div class="plate_num" @touchstart="chooseValue('川')">川</div>
        <div class="plate_num" @touchstart="chooseValue('云')">云</div>
        <div class="plate_num" @touchstart="chooseValue('贵')">贵</div>
        <div class="plate_num" @touchstart="chooseValue('渝')">渝</div>
        <div class="plate_num" @touchstart="chooseValue('藏')">藏</div>
        <div class="plate_num" @touchstart="chooseValue('辽')">辽</div>
        <div class="plate_num" @touchstart="chooseValue('吉')">吉</div>
        <div class="plate_num" @touchstart="chooseValue('黑')">黑</div>
        <div class="plate_num" @touchstart="chooseValue('使')">使</div>
        <div class="plate_num" @touchstart="chooseValue('无')">无</div>
        <div class="null"></div>
        <div class="null"></div>
        <div class="plate_num del_ico" @touchstart="del">
          <van-icon name="clear" />
        </div>
      </div>
      <div class="num_board" v-else @touchstart="numEvent">
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">1</div>
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">2</div>
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">3</div>
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">4</div>
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">5</div>
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">6</div>
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">7</div>
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">8</div>
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">9</div>
        <div class="num" :class="chooseIndex > 1 ? '' : 'num_onlyRead'">0</div>

        <div class="num">Q</div>
        <div class="num">E</div>
        <div class="num">W</div>
        <div class="num">R</div>
        <div class="num">T</div>
        <div class="num">Y</div>
        <div class="num">U</div>
        <div class="num num_onlyRead">O</div>
        <div class="num">P</div>
        <div
          class="num"
          :class="chooseIndex != 6 ? 'num_onlyRead' : ''"
          @touchstart.stop="chooseCnValue('港')"
        >
          港
        </div>

        <div class="num">A</div>
        <div class="num">S</div>
        <div class="num">D</div>
        <div class="num">F</div>
        <div class="num">G</div>
        <div class="num">H</div>
        <div class="num">J</div>
        <div class="num">K</div>
        <div class="num">L</div>
        <div
          class="num"
          :class="chooseIndex != 6 ? 'num_onlyRead' : ''"
          @touchstart.stop="chooseCnValue('澳')"
        >
          澳
        </div>

        <div class="num">Z</div>
        <div class="num">X</div>
        <div class="num">C</div>
        <div class="num">V</div>
        <div class="num">B</div>
        <div class="num">N</div>
        <div class="num">M</div>
        <div
          class="num"
          :class="chooseIndex != 6 ? 'num_onlyRead' : ''"
          @touchstart.stop="chooseCnValue('学')"
        >
          学
        </div>
        <div
          class="num"
          :class="chooseIndex != 6 ? 'num_onlyRead' : ''"
          @touchstart.stop="chooseCnValue('领')"
        >
          领
        </div>
        <div class="num" @touchstart.stop="del">
          <van-icon name="clear" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "plateKeyboard",
  props: {
    value: String,
    default: String,
    oldPlate: String,
  },
  data() {
    return {
      key: "请输入车牌",
      i: 8,
      plate: "",
      chooseIndex: -1,
      inputArray: [this.default, "", "", "", "", "", "", ""],
      isCN: false,
    };
  },
  watch: {
    oldPlate(old) {
      if (old.split("").length >= 7) {
        old.split("").forEach((item, i) => {
          this.$set(this.inputArray, i, item);
        });
        this.emitValue();
      }
    },
  },
  methods: {
    complateChild() {
      this.chooseIndex = -1;
    },
    selectedNew() {
      if (this.plate.split("").length == 0) {
        this.chooseIndex = 0;
      } else {
        this.chooseIndex = this.plate.split("").length - 1;
      }

      // console.log(this.inputArray);
    },
    //选中输入框事件
    selected(i) {
      this.chooseIndex = i;
    },
    numEvent(event) {
      event.target.className != "num_board"
        ? this.chooseValue(event.target.innerText)
        : "";
    },
    //选择中文字符改变class
    chooseCnValue(value) {
      this.$set(this.inputArray, this.chooseIndex, value);
      this.chooseIndex != 7 ? this.chooseIndex++ : "";
      this.isCN = true;
      this.emitValue();
    },
    //赋值后选中下一个输入框
    chooseValue(value) {
      this.$set(this.inputArray, this.chooseIndex, value);
      this.chooseIndex != 7 ? this.chooseIndex++ : "";
      this.emitValue();
    },
    //删除事件
    del() {
      if (this.inputArray[this.chooseIndex]) {
        this.$set(this.inputArray, this.chooseIndex, "");
        this.emitValue();
        if (this.chooseIndex == 6) {
          this.isCN = false;
        }
      } else {
        if (this.chooseIndex > 0) {
          this.$set(this.inputArray, this.chooseIndex - 1, "");
          this.chooseIndex = this.chooseIndex - 1;
          if (this.chooseIndex == 6) {
            this.isCN = false;
          }
          this.emitValue();
        }
      }
    },
    emitValue() {
      console.log(this.inputArray);
      this.$emit(
        "input",
        this.inputArray[0] +
          this.inputArray[1] +
          this.inputArray[2] +
          this.inputArray[3] +
          this.inputArray[4] +
          this.inputArray[5] +
          this.inputArray[6] +
          this.inputArray[7]
      );
      this.plate = "";
      for (let i = 0; i < this.inputArray.length; i++) {
        this.plate += this.inputArray[i];
      }
    },
    complate() {
      this.chooseIndex = -1;
    },
  },
};
</script>

<style scoped lang="scss">
.key_input {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  color: #303030;
  font-family: PingFang SC-Regular, PingFang SC;
  font-size: 1.4rem;
  font-weight: 500;
  color: #303030;
  .spot {
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background: #1989fa;
    margin-right: 0.4rem;
  }
  .num {
    border: 1px solid #d7d7d7;
    margin-right: 0.4rem;
    flex: 1;
    padding-bottom: 10%;
    border-radius: 0.5rem;
    position: relative;
    div {
      font-size: 1.4rem;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: 12%;
      color: #1989fa;
    }
    span {
      position: absolute;
      left: 0.4rem;
      right: 0.4rem;
      background: #1989fa;
      bottom: 0.3rem;
    }
    .cn {
      font-size: 2.3rem;
      margin-top: -1.6rem;
    }
  }
  .num:first-child {
    div {
      font-size: 1.4rem;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: 1%;
    }
  }
  .num:last-child {
    margin-right: 0;
    border: 1px solid #67c23a;
  }
  .blink {
    height: 0.2rem;
    border-radius: 0.3rem;
    animation: blink 1.2s infinite steps(1, start);
  }
  .hasData {
    font-size: 1.4rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #303030;
  }
  .nullData {
    font-size: 1.4rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #d0d0d0;
  }
}
.board {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cfd4db;
  z-index: 99999;
  .b_header {
    height: 3.5rem;
    background-color: #fff;
    color: #1989fa;
    line-height: 3.5rem;
    font-size: 1.5rem;
    text-align: right;
    padding-right: 1rem;
    border-top: 1px solid #e7e7e7;
  }
  .area_board {
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    .plate_num {
      min-width: 3.0333rem;
      min-height: 3.8rem;
      background-color: #fff;
      border-radius: 0.3rem;
      line-height: 3.8rem;
      font-size: 1.8rem;
      box-shadow: 1px 1px 1px #999;
      margin-right: 1rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    .null {
      min-width: 3.0333rem;
      min-height: 3.8rem;
      margin-right: 1rem;
    }
    .del_ico {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .plate_num:nth-child(9n + 0) {
      margin-right: 0;
    }
    .plate_num:active {
      background-color: #1989fa;
      color: #fff;
    }
  }
  .num_board {
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    .num {
      min-width: 3rem;
      min-height: 3.8rem;
      background-color: #fff;
      border-radius: 0.3rem;
      line-height: 3.8rem;
      font-size: 1.9rem;
      box-shadow: 1px 1px 1px #999;
      margin-right: 0.6rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    .num_onlyRead {
      color: #d7d7d7;
      pointer-events: none;
    }
    .num:nth-child(10n + 0) {
      margin-right: 0;
    }
    .num:active {
      background-color: #1989fa;
      color: #fff;
    }
  }
}
@keyframes blink {
  0%,
  50% {
    opacity: 0; /* not #aaa because it's seem there is Google Chrome bug */
  }
}
</style>